/*
 eslint-disable
 no-shadow,
 no-new,
 eqeqeq
*/

function SyncService() {}

SyncService.prototype.wait = function (callback, period, waitCondition, breakCondition) {
    if (breakCondition == null || !breakCondition()) {
        var interval;

        if (!waitCondition()) {
            callback();
        } else if (breakCondition == null || !breakCondition()) {
            interval = setInterval(function () {
                if (!waitCondition()) {
                    clearInterval(interval);
                    if (breakCondition == null || !breakCondition()) {
                        callback();
                    }
                }
            }, period);
        } else {
            clearInterval(interval);
        }
    }
};

SyncService.prototype.countDown = function (trigger, number, callback) {
    function CountDown(trigger, number, callback) {
        this.t = trigger;
        this.n = number;
        this.c = callback;
        this.t();
    }

    CountDown.prototype.countDown = function () {
        this.n = this.n - 1;
        if (this.n == 0) {
            this.c();
        }
    };

    new CountDown(trigger, number, callback);
};

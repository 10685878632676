/*
 eslint-disable
 no-param-reassign,
 eqeqeq
*/

/*
 globals
 $
 Util
*/

function ImageService(presetService, syncService, presetObject) {
    this.presetService = presetService;
    this.syncService = syncService;
    this.presetObject = presetObject;
}

ImageService.prototype.initPresets = function (rootElement, updateCallback, lazy, presetType) {
    var serviceSelf = this;
    var presetKey = this.presetService.getPresetKey();
    var $images = serviceSelf.getPresetImages(rootElement);

    serviceSelf.syncService.countDown(function () {
        var countDown = this;

        $images.each(function () {
            var img = $(this);
            var presetGroup = serviceSelf.presetObject[Util.defaultIfEmpty(presetType, img.attr('preset-type'))];
            var srcPreset = img.attr('src-np');
            if (Util.isNotEmpty(presetGroup)) {
                var preset = presetGroup[presetKey];
                var suffix = Util.defaultIfEmpty(presetGroup.suffix, '');
                if (Util.isNotEmpty(preset)) {
                    srcPreset = Util.addPreset(img.attr('src-np'), suffix, preset);
                }
            }
            if (img.hasClass('hide')) {
                img.removeClass('hide');
            }
            if (lazy) {
                if (img.attr('data-src') != srcPreset || img.attr('src') != srcPreset) {
                    img.attr('data-src', srcPreset);
                    img.data('handled', false);
                }
                countDown.countDown();
            } else if (srcPreset && img.attr('src') != srcPreset) {
                img.load(function () {
                    countDown.countDown();
                });
                img.attr('src', srcPreset);
            } else {
                countDown.countDown();
            }
        });
    }, $images.length, function () {
        if (updateCallback != null) {
            updateCallback();
        }
    });
};

ImageService.prototype.isCached = function (imageUrl, presetType) {
    imageUrl = Util.defaultIfEmpty(imageUrl, '');
    presetType = Util.defaultIfEmpty(presetType, '');
    var presetKey = this.presetService.getPresetKey();
    var presetGroup = this.presetObject[presetType];
    if (Util.isNotEmpty(presetGroup)) {
        var preset = presetGroup[presetKey];
        var suffix = Util.defaultIfEmpty(presetGroup.suffix, '');
        if (Util.isNotEmpty(preset)) {
            imageUrl = Util.addPreset(imageUrl, suffix, preset);
        }
    }
    var image = new Image();
    image.src = imageUrl;
    return image.complete;
};

ImageService.prototype.initLazy = function (rootElement, options) {
    var serviceSelf = this;

    var $images = serviceSelf.getPresetImages(rootElement).filter(function () {
        return $(this).data('plugin_lazy') == null;
    });

    $images.each(function () {
        var src = $(this).attr('src');
        var dataSrc = $(this).attr('data-src');
        if (Util.isNotEmpty(dataSrc) && Util.isNotEmpty(src) && src != dataSrc) {
            $(this).data('handled', false);
        }
    });

    var defaults = {
        chainable: false,
        visibleOnly: true,
        threshold: 999999,
        autoDestroy: false,
        onError: function (element) {
            element.data('handled', false);
            setTimeout(function () {
                serviceSelf.triggerLazy();
            }, 5000);
        }
    };

    var settings = $.extend({}, defaults, options);

    return $images.lazy(settings);
};

ImageService.prototype.forceLazy = function (items) {
    if ($(items).length) {
        this.initLazy(items);
        var lazyInst = $(items).data('plugin_lazy');
        if (lazyInst != null) {
            lazyInst.addItems(items);
            lazyInst.force(items);
        }
    }
};

ImageService.prototype.getPresetImages = function (rootElement) {
    return $(rootElement || 'body').find('.preset').add($(rootElement).filter('.preset'));
};

ImageService.prototype.triggerLazy = function () {
    $(window).trigger('scroll');
};

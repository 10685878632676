/*
 eslint-disable
 prefer-rest-params
*/

/*
 globals
 $
*/

/**
 *
 * @param fnc function to execute
 * @returns {Function} input function wrapper, executes input function and triggers dropdownsChangeHandled event
 */
function updateDropdownsAndNotify(fnc) {
    return function () {
        fnc.apply(this, arguments);
        $(this).trigger('dropdownsChangeHandled');
    };
}

/**
 * Gets default swatch colour from gallery because gallery is always present on PDP
 * @returns {jQuery}
 */
function getDefaultColour() {
    return $('.js-carousel-product-images[data-swatch-info]:first').data('swatch-info');
}

function isLocalServer() {
    return window.location.host.startsWith('localhost');
}

function getCsrfToken() {
    if (isLocalServer()) {
        return '';
    }

    return window.ACC.config.CSRFToken;
}

function getUnmodifiedCsrfToken() {
    return window.ACC.config.UnmodifiedCSRFToken;
}

function vanillaFetch(endpoint, data, success, failure) {
    return fetch(`/${window.location.pathname.split('/').filter(s => s)[0]}${endpoint}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-Csrf-Token': getCsrfToken(),
            'X-Csrf-Unmodified-Token': getUnmodifiedCsrfToken(),
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.status.length !== 0) {
                switch (response.status) {
                    case 403: {
                        if (failure) {
                            failure();
                            return false;
                        }
                        break;
                    }
                    case 200: {
                        if (success) {
                            success(response.status);
                        }
                        break;
                    }
                    default: {
                        // eslint-disable-next-line
                        console.log("status response does not match any expected");
                        failure();
                        return false;
                    }
                }
            }

            return response;
        });
}
function addToWishlist(requestPayload, selectedDiv) {
    const filledIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--filled.svg';

    return fetch(window.ACC.config.baseUrl + 'api/wishlist', {
        method: 'POST',
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Content-Type': 'application/json',
            'X-Csrf-Token': window.ACC.config.CSRFToken,
        },
        body: JSON.stringify(requestPayload),
        dataType: 'html'
    })
        .then(function (response) {
            if (response.status === 200) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then(function (data) {
            var wishlistResponse = data;
            if (wishlistResponse !== null) {
                //  check if error modal is open
                if ($('#modal-wishlist').is(':visible')) {
                    $('#modal-wishlist').modal('hide');
                }

                // toggle heart icon
                selectedDiv.find('.wishlist-icon').removeClass('heart-empty').addClass('heart-filled');
                selectedDiv.find('.wishlist-icon').attr('src', filledIconPath);


                selectedDiv.find('.wishlist-icon').next().css('display', 'none');
                selectedDiv.find('.wishlist-icon').css('display', 'block');
                // Update universal variable data
                var categoryDetails = window.universal_variable.product;
                // TODO: add remaining fields which are used for analytics
                var categoryDetailsToAdd = {
                    category: categoryDetails.category,
                    category_id: categoryDetails.category_id,
                    name: categoryDetails.name
                };

                var productDetailsToAdd = {
                    position: wishlistResponse.position,
                    productCode: requestPayload.productCode,
                    colourCode: requestPayload.colourCode,
                    collectionGroupCode: requestPayload.collectionGroupCode,
                    product: []
                };
                productDetailsToAdd.product.push(categoryDetailsToAdd);

                window.universal_variable.saved_items.number_of_entries = wishlistResponse.numberOfEntries;
                window.universal_variable.saved_items.entries.push(productDetailsToAdd);

                // dispatch event to update wishlist icon state
                if (wishlistResponse.numberOfEntries === 1) {
                    var event = new CustomEvent('WISHLIST_ICON_STATUS_UPDATE');
                    window.dispatchEvent(event);
                }

                // wishlist dataset updated - delete cache as its invalid
                caches.delete('wishlist-responses');
            }
        })
        .catch(function () {
            $('#modal-wishlist').modal('show');
            // only show add product to wishlist error message
            $('.wishlist-error').hide();
            $('#add-to-wishlist-error').show();
            $('.btn-retry').click(function (e) {
                e.preventDefault();
                $('#modal-wishlist').modal('hide');
            });

            selectedDiv.find('.wishlist-icon').next().css('display', 'none');
            selectedDiv.find('.wishlist-icon').css('display', 'block');
        });
}
function removeFromWishlist(positionId, selectedDiv) {
    const emptyIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--empty.svg';

    return fetch(window.ACC.config.baseUrl + 'api/wishlist/' + positionId, {
        method: 'DELETE',
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Content-Type': 'application/json',
            'X-Csrf-Token': window.ACC.config.CSRFToken,
        },
        dataType: 'html'
    })
        .then(function (response) {
            if (response.status === 200) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then(function (data) {
            var responseData = data;
            if (responseData) {
                // toggle heart icon
                selectedDiv.find('.wishlist-icon').removeClass('heart-filled').addClass('heart-empty');
                selectedDiv.find('.wishlist-icon').attr('src', emptyIconPath);

                selectedDiv.find('.wishlist-icon').next().css('display', 'none');
                selectedDiv.find('.wishlist-icon').css('display', 'block');

                // Update universal variable data
                window.universal_variable.saved_items.number_of_entries = responseData.numberOfEntries;
                var productIndex = window.universal_variable.saved_items.entries.findIndex(function (element) {
                    return element.position === positionId;
                });

                window.universal_variable.saved_items.entries.splice(productIndex, 1);

                // dispatch event to update wishlist icon state
                if (responseData.numberOfEntries === 0) {
                    var event = new CustomEvent('WISHLIST_ICON_STATUS_UPDATE');
                    window.dispatchEvent(event);
                }

                // wishlist dataset updated - delete cache as its invalid
                caches.delete('wishlist-responses');
            }
        })
        .catch(function () {
            $('#modal-wishlist').modal('show');
            // only show remove product from wishlist  error message
            $('.wishlist-error').hide();
            $('#delete-wishlist-error').show();
            $('.btn-retry').click(function (e) {
                e.preventDefault();
                $('#modal-wishlist').modal('hide');
            });


            selectedDiv.find('.wishlist-icon').next().css('display', 'none');
            selectedDiv.find('.wishlist-icon').css('display', 'block');
        });
}
function isProductExistInWishlist(productCode, colourCode, collectionGroupCode) {
    var savedItemsObject = window.universal_variable.saved_items;
    var entriesArray = savedItemsObject.entries;
    var productEntry = null;

    if (!colourCode && !collectionGroupCode) {
        productEntry = productCode
            ? entriesArray.find(function (data) { return data.productCode === productCode; }) : null;
    } else if (!collectionGroupCode) {
        productEntry = productCode
            ? entriesArray.find(function (data) {
                return data.productCode === productCode && (data.colourCode === colourCode.toString() || data.colourCode === colourCode)
                && data.collectionGroupCode === collectionGroupCode;
            }) : null;
    } else {
        productEntry = productCode
            ? entriesArray.find(function (data) {
                return data.productCode === productCode && (data.colourCode === colourCode.toString() || data.colourCode === colourCode)
                && data.collectionGroupCode === collectionGroupCode;
            }) : null;
    }
    return productEntry;
}
function toggleTileGroupCollectionWishlistIcon() {
    var collectionProductCode = window.universal_variable.product.id;
    var collectionContainer = $('#product-details');
    var collectionSwatchContainer = $('#product-details').find('.SwatchContainer');
    var isCollectionExistInWishlist = null;
    var collectionColourcode = collectionSwatchContainer.find('li.active').data('swatch-colourcode') || 1;

    if (!($('#product-details').find('#addToCartForm').length)) {
        isCollectionExistInWishlist = window.addToBagCommons.isProductExistInWishlist(collectionProductCode, collectionColourcode, null);

        var filledIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--filled.svg';
        var emptyIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--empty.svg';
        var selectedWishlistIcon = collectionContainer.find('.wishlist-icon');

        if (isCollectionExistInWishlist) {
            selectedWishlistIcon.removeClass('heart-empty').addClass('heart-filled');
            selectedWishlistIcon.attr('src', filledIconPath);
        } else if (selectedWishlistIcon.hasClass('heart-filled')) {
            selectedWishlistIcon.removeClass('heart-filled').addClass('heart-empty');
            selectedWishlistIcon.attr('src', emptyIconPath);
        }
    }
}
function checkMaximumWishlistLimitReached(requestPayload, selectedDiv) {
    var currentNumberOfEntries = window.universal_variable.saved_items.number_of_entries;
    if (currentNumberOfEntries >= window.ACC.config.wishlist.maximum.entries) {
        $('#modal-wishlist').modal('show');
        // only show limit exceeded error message
        $('.wishlist-error').hide();
        $('#wishlist-limit-exceeded-error').show();

        $('#modal-wishlist').on('hidden.bs.modal', function () {
            if ($('#modal-wishlist')) {
                $('#modal-wishlist').css('display', 'none');
                selectedDiv.find('.wishlist-icon').css('display', 'block');
                selectedDiv.find('.wishlist-icon').next().css('display', 'none');
            }
        });
        $('.replace-item').click(function (event) {
            event.preventDefault();
            addToWishlist(requestPayload, selectedDiv);
        });

        $('.go-to-saveditems').click(function () {
            window.location.href = '/saved-items';
        });
    } else {
        addToWishlist(requestPayload, selectedDiv);
    }
}
export {
    getDefaultColour, updateDropdownsAndNotify, vanillaFetch, addToWishlist, removeFromWishlist,
    isProductExistInWishlist, toggleTileGroupCollectionWishlistIcon, checkMaximumWishlistLimitReached
};

/*
 eslint-disable
 consistent-return,
 max-len,
 eqeqeq
*/

/*
 globals
 Util
 ACC
*/

var ProductUtil = {};

ProductUtil.getCategory = function (item) {
    var defaultCategory = Util.defaultIfEmpty(item.defaultCategory, {});
    var superCategory = Util.defaultIfEmpty(Util.defaultIfEmpty(item.superCategories, [])[0], {});
    return Util.defaultIfEmpty(defaultCategory, superCategory);
};

ProductUtil.getSubcategory = function (item) {
    return Util.defaultIfEmpty(Util.defaultIfEmpty(item.categories, [])[0], {});
};

ProductUtil.getSize = function (item) {
    var size = '';
    if (Util.isNotEmpty(item.baseOptions)) {
        item.baseOption.forEach(function (baseOption) {
            var variantOption = baseOption.selected;
            if (variantOption != null && variantOption.code == item.code) {
                if (variantOption.sizeCode != null) {
                    return variantOption.sizeCode;
                }
                return ACC.message.plp.oneSize;
            }
        });
    }
    return size;
};

ProductUtil.getColor = function (item) {
    var color = '';
    if (Util.isNotEmpty(item.baseOptions)) {
        item.baseOption.forEach(function (baseOption) {
            var variantOption = baseOption.selected;
            if (variantOption != null && variantOption.code == item.code) {
                if (variantOption.colourCode != null) {
                    return variantOption.colourCode;
                }
                return ACC.message.plp.noColour;
            }
        });
    }
    return color;
};

ProductUtil.getStock = function (item) {
    if (item.stock != null && item.stock.stockLevelStatus != null && item.stock.stockLevelStatus != 'OUTOFSTOCK' && item.stock.stockLevel != null) {
        return item.stock.stockLevel;
    }
    return 0;
};

ProductUtil.getPrice = function (item) {
    return Util.defaultIfNull(item.price, {});
};

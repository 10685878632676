/*
 eslint-disable
 no-unused-vars
*/

/*
 globals
 $
 addToWishlist
 removeFromWishlist
 isProductExistInWishlist
*/

var AddToBagBaseHelper = (function () {
    var instance;


    function createInstance() {
        var DataHolder = function () {};

        function changePrice(selectObject, selectedOption) {
            if (!selectObject.data('is-one-price')) {
                var priceForVariantSelector = $('div.product-price-for-selected-variant');
                var selectedSku = selectedOption.data('sku');

                if (selectedSku !== '') {
                    $('div[class^="colour-specific-"]').parent().addClass('hide');
                    priceForVariantSelector.children().remove();

                    $('#priceForSizeTemplate').tmpl({
                        wasPrice: selectedOption.data('wasprice'),
                        price: selectedOption.data('price')
                    }).appendTo(priceForVariantSelector);

                    priceForVariantSelector.removeClass('hide');
                } else {
                    var swatchColour = selectObject.data('swatch-size-selector');
                    priceForVariantSelector.addClass('hide');
                    $('div[class^="colour-specific-"]').parent().addClass('hide');
                    $('div[class="colour-specific-' + swatchColour + '"]').parent().removeClass('hide');
                }
            }
        }

        function createQuantityArray(stockLevel, isOptionPurchasable) {
            var selectQtyDiv = $('.select-qty');
            var selectQtySelectpicker = selectQtyDiv.find('.selectpicker');

            if (stockLevel === 0 || !isOptionPurchasable) {
                selectQtySelectpicker.addClass('hide');
                selectQtySelectpicker.parent().addClass('hide');
                selectQtyDiv.find('.no-stock').removeClass('hide');
            } else {
                selectQtySelectpicker.removeClass('hide');
                selectQtySelectpicker.parent().removeClass('hide');
                selectQtySelectpicker.attr('disabled', false);
                selectQtySelectpicker.find('[data-value]').remove();
                selectQtyDiv.find('.no-stock').addClass('hide');

                var qtyArray = [];
                for (var i = 1; i <= stockLevel; i++) {
                    qtyArray.push(i);
                }

                $('#qtyAddOptionsTemplate').tmpl({
                    qtyArray: qtyArray
                }).appendTo(selectQtySelectpicker);

                selectQtySelectpicker.selectpicker('refresh');
            }
        }

        function setQuantity(stockLevel, isOptionPurchasable) {
            var qty = '';
            if (stockLevel > 0 && isOptionPurchasable) {
                qty = 1;
            }
            $('#addToCartForm').find('#productQuantity').val(qty);
        }

        function toggleFindStockInStoreButton(isAddToBagEnabled) {
            var fsisContainer = $('#addToCartForm').siblings('.find-stock-in-store__container');
            var fsisButton = fsisContainer.find('.find-stock-in-store__cta');
            var primaryClass = 'find-stock-in-store__cta--primary';
            var secondaryClass = 'find-stock-in-store__cta--outline';

            if (!fsisButton) {
                return;
            }

            if (
                (isAddToBagEnabled && fsisButton.hasClass(primaryClass))
                || (!isAddToBagEnabled && fsisButton.hasClass(secondaryClass))
            ) {
                fsisButton.toggleClass(primaryClass);
                fsisButton.toggleClass(secondaryClass);
            }
        }

        function toggleAddToBagButton() {
            var addToCartForm = $('#addToCartForm');
            var isCollectionPurchasable = (addToCartForm.find('#isCollectionPurchasable').val() === 'true');
            var isProductSelected = (addToCartForm.find('#productCodePost').val() !== '');
            var isQtySelected = (addToCartForm.find('#productQuantity').val() !== '');
            var isAddToBagEnabled = isCollectionPurchasable && isProductSelected && isQtySelected;

            window.addToBagButton.getButton('#add-to-bag-button').toggleDisabled(!isAddToBagEnabled);

            toggleFindStockInStoreButton(isAddToBagEnabled);
        }

        function toggleWishlistIcon() {
            if (window.ACC.config.toggles.wishList.CTA.pdpEnabled) {
                var addToCartForm = $('#addToCartForm');
                var productDetails = $('#product-details');
                var currentProductCode = addToCartForm.find('#productCodePost').val();
                var selectedWishlistIcon = productDetails.find('.wishlist-icon');
                var colourCode = null;
                var collectionGroupCode = null;
                var isProductExistInWishlist = null;


                if (!currentProductCode) {
                    currentProductCode = $('#product-code').text().trim();
                    colourCode = $('.SwatchContainer').find('li.active').data('swatch-colourcode');
                }
                isProductExistInWishlist = window.addToBagCommons
                    .isProductExistInWishlist(currentProductCode, colourCode, collectionGroupCode);

                var filledIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--filled.svg';
                var emptyIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--empty.svg';

                if (isProductExistInWishlist) {
                    selectedWishlistIcon.removeClass('heart-empty').addClass('heart-filled');
                    selectedWishlistIcon.attr('src', filledIconPath);
                } else if (selectedWishlistIcon.hasClass('heart-filled')) {
                    selectedWishlistIcon.removeClass('heart-filled').addClass('heart-empty');
                    selectedWishlistIcon.attr('src', emptyIconPath);
                }
            }
        }

        function displayNotifyMe() {
            var areSwatchesAvailable = $('.swatch.active').length > 0;
            var oneSize = $('#size-single-number');
            var isOneSizeSetToNotifyMe = oneSize.length > 0 && oneSize.data('notifyme');

            if (areSwatchesAvailable) {
                var currentSwatchColourName = $('.swatch.active').data('swatch-colour');

                // One size products
                if (oneSize.length > 0) {
                    // eslint-disable-next-line max-len
                    var isCurrentOneSizeProductSetToNotifyMe = $('#size-single-number[data-swatch-size-selector="' + currentSwatchColourName + '"]').data('notifyme');

                    return {
                        displayBanner: isCurrentOneSizeProductSetToNotifyMe,
                        displaySizeMessage: false
                    };
                }

                // Multi size products
                if (oneSize.length === 0) {
                    // eslint-disable-next-line max-len
                    var selectedVariantTarget = $($('.select-size .selectpicker[data-swatch-size-selector="' + currentSwatchColourName + '"]'))[0];
                    var selectedVariantSizeValue = selectedVariantTarget.value;
                    var selectedVariantSizeValueDefault = selectedVariantSizeValue.includes('Select size');
                    var selectedVariantSizeValueNotifyMe = selectedVariantSizeValue.includes('Notify Me');
                    var selectedVariantSizeOptions = selectedVariantTarget.options;
                    var atLeastOneVariantIsSetTonotifyMe = Array.from(selectedVariantSizeOptions).every(function (option, index) {
                        if (index === 0) {
                            return true;
                        }

                        return option.dataset.notifyme === 'true';
                    });

                    // Default selection and at least one product is set to notify me
                    if (selectedVariantSizeValueDefault && atLeastOneVariantIsSetTonotifyMe) {
                        return {
                            displayBanner: true,
                            displaySizeMessage: true
                        };
                    }

                    // Selected size option is set to notify me
                    if (!selectedVariantSizeValueDefault && selectedVariantSizeValueNotifyMe) {
                        return {
                            displayBanner: true,
                            displaySizeMessage: false
                        };
                    }
                }
            }

            if (!areSwatchesAvailable && isOneSizeSetToNotifyMe) {
                return {
                    displayBanner: true,
                    displaySizeMessage: false
                };
            }

            return {
                displayBanner: false,
                displaySizeMessage: false
            };
        }

        function toggleNotifyMeBanner() {
            var notifyMeBanner = $('#notify-me-banner');
            var notifyMe = displayNotifyMe();

            // Remove notify me banner if it exists
            if (notifyMeBanner) {
                notifyMeBanner.remove();
            }

            if (notifyMe.displayBanner) {
                var bannerWrapper = document.createElement('div');
                bannerWrapper.classList.add('col-xs-12');
                bannerWrapper.id = 'notify-me-banner';

                var banner = document.createElement('div');
                banner.classList.add('notify-me__banner');

                var bannerIcon = document.createElement('img');
                bannerIcon.classList.add('notify-me__banner-icon');
                bannerIcon.src = window.ACC.config.commonResourcePath + '/images/information-new.svg';

                var bannerContent = document.createElement('div');
                bannerContent.classList.add('notify-me__banner-content');

                var bannerContentTitle = document.createElement('span');
                bannerContentTitle.classList.add('notify-me__banner-content-title');
                bannerContentTitle.innerText = 'This item is currently out of stock';

                var bannerContentBody = document.createElement('span');
                bannerContentBody.classList.add('notify-me__banner-content-body');
                bannerContentBody.innerText = notifyMe.displaySizeMessage
                    ? "Select your size and enter your email and we'll let you know as soon as it's back."
                    : "Enter your email and we'll let you know as soon as it's back.";

                bannerContent.appendChild(bannerContentTitle);
                bannerContent.appendChild(bannerContentBody);

                banner.appendChild(bannerIcon);
                banner.appendChild(bannerContent);

                bannerWrapper.appendChild(banner);

                $('#product-image').before(bannerWrapper);
            }
        }

        function toggleNotifyMeForm() {
            var addToCartForm = $('#addToCartForm');
            var isNotifyMe = (addToCartForm.find('#isNotifyMe').val() === 'true');

            $('#notify-me').removeClass('prompt--show');
            if (isNotifyMe) {
                $('#notify-me').removeClass('submit--success').addClass('prompt--show');
            }
            toggleNotifyMeBanner();
        }

        function toggleNotifyMeFormOneSize() {
            var oneSize = $('#size-single-number');
            if (oneSize.length > 0 && oneSize.data('notifyme')) {
                $('#addToCartForm').find('#isNotifyMe').val(true);
                toggleNotifyMeForm();
            }
        }

        function toggleBackorder(selectedOption) {
            if (!selectedOption) {
                return;
            }

            var isBackOrder = selectedOption.data('isbackorder');
            var nextDeliveryDate = selectedOption.data('nextdeliverydate');

            var backOrder = $('.js-backorder');
            var backOrderCopy = backOrder.data('copy');
            var backOrderCopySpan = $('.js-backorder-copy');

            if (nextDeliveryDate) {
                backOrderCopySpan.html(backOrderCopy.replace('{0}', nextDeliveryDate));
            }

            backOrder.toggleClass('hide', !(isBackOrder && nextDeliveryDate));
        }

        function toggleBackorderOneSize() {
            var oneSize = $('#size-single-number');
            if (!oneSize.length) {
                return;
            }

            toggleBackorder(oneSize);
        }

        function onQtyOptionChange(event) {
            var selected = $(this).find('option:selected');
            $('#addToCartForm').find('#productQuantity').val(selected.data('value'));
            toggleAddToBagButton();
        }

        function onSizeOptionChange(event) {
            var selectObject = $(this);
            var selectedOption = selectObject.find('option:selected');

            var isOptionPurchasable = selectedOption.data('isoptionpurchasable');
            $('#addToCartForm').find('#isOptionPurchasable').val(isOptionPurchasable);

            // notifyMe checks a product's stock and their eligibility for back in stock against a few conditions in backoffice.
            var isNotifyMeEnabled = selectedOption.data('notifyme');
            $('#addToCartForm').find('#isNotifyMe').val(isNotifyMeEnabled);

            var stockLevel = selectedOption.data('stocklevel');
            createQuantityArray(stockLevel, isOptionPurchasable);

            $('#addToCartForm').find('#productCodePost').val(selectedOption.data('sku'));
            setQuantity(stockLevel, isOptionPurchasable);
            toggleAddToBagButton();
            toggleNotifyMeForm();
            changePrice(selectObject, selectedOption);

            toggleWishlistIcon();

            toggleBackorder(selectedOption);
        }

        DataHolder.prototype.initDropdowns = function (currentSwatch) {
            var me = this;
            me.store = {};
            me.selectedSwatchColour = '';

            if (currentSwatch) {
                me.selectedSwatchColour = currentSwatch.toString().toLowerCase().replace(new RegExp(' ', 'g'), '-');
            }

            $('.select-size').find('.selectpicker')
                .on('change', window.addToBagCommons.updateDropdownsAndNotify(onSizeOptionChange));
            $('.select-qty').find('.selectpicker')
                .on('change', window.addToBagCommons.updateDropdownsAndNotify(onQtyOptionChange));

            toggleNotifyMeBanner();
        };

        DataHolder.prototype.showSelectSizeAndQuantityBySwatchColour = function (swatchColour) {
            var me = this;
            me.selectedSwatchColour = swatchColour;

            var addToCartForm = $('#addToCartForm');
            addToCartForm.find('#isNotifyMe').val(false);
            addToCartForm.find('#isCollectionPurchasable').val(false);
            addToCartForm.find('#isOptionPurchasable').val(false);

            // hide all div's and selects
            $('div[data-swatch-size-selector]').addClass('hide');
            $('select[data-swatch-size-selector]').addClass('hide');
            $('select[data-swatch-size-selector]').parent().addClass('hide');

            var activeDiv = $("div[data-swatch-size-selector='" + swatchColour + "']");
            // case when one size or sold out colour
            if (activeDiv.length > 0) {
                activeDiv.removeClass('hide');

                var isOptionPurchasable = activeDiv.data('isoptionpurchasable');
                var stockLevel = activeDiv.data('stocklevel');
                var notifyMe = activeDiv.data('notifyme');

                createQuantityArray(stockLevel, isOptionPurchasable);
                $('#addToCartForm').find('#productCodePost').val(activeDiv.data('sku'));

                setQuantity(stockLevel, isOptionPurchasable);

                if (stockLevel > 0 && isOptionPurchasable) {
                    addToCartForm.find('#isCollectionPurchasable').val(true);
                }

                if (notifyMe) {
                    addToCartForm.find('#isNotifyMe').val(true);
                }
            } else {
                // case with multiple sizes
                var activeSelect = $("select[data-swatch-size-selector='" + swatchColour + "']");
                if (activeSelect.length > 0) {
                    activeSelect.removeClass('hide');
                    activeSelect.parent().removeClass('hide');
                    activeSelect[0].selectedIndex = 0;
                    activeSelect.selectpicker('refresh');

                    // reset and disable quantity dropdown
                    var selectQty = $('.select-qty');
                    var selectQtySelectpicker = selectQty.find('.selectpicker');
                    selectQtySelectpicker.addClass('hide');
                    selectQtySelectpicker.parent().addClass('hide');

                    selectQty.find('.no-stock').removeClass('hide');

                    // reset form
                    addToCartForm.find('#productCodePost').val('');
                    addToCartForm.find('#productQuantity').val('');

                    addToCartForm.find('#isOptionPurchasable').val(true);
                    addToCartForm.find('#isCollectionPurchasable').val(true);
                }
            }

            toggleAddToBagButton();
            toggleNotifyMeForm();
            toggleWishlistIcon();
        };

        // wishlist icon changes
        $('.wishlist-icon').click(function (e) {
            e.preventDefault();
            var selectedObject = $(this);
            var selectedSliderDiv = selectedObject.closest('div.js-stl-product');
            var productCode = $('#addToCartForm').find('#productCodePost').val();
            var colourCode = null;
            var collectionGroupCode = null;
            var selectedDiv = $(this).closest('#product-details');
            var selectedWishlistIcon = $('#addToCartForm').find('.wishlist-icon');
            var divToSend = selectedDiv;

            var wishlistContainer = selectedObject.closest('div.wishlist-icon__wrapper');
            wishlistContainer.fadeIn('slow', function () {
                selectedObject.css('display', 'none');
                selectedObject.next().css('display', 'block');
            });

            if (selectedSliderDiv.length) {
                var selectedSliderSwatchContainer = selectedSliderDiv.find('.SwatchContainer');
                var activeSwatch = selectedSliderSwatchContainer.find('li.active');
                var addToBagButton = selectedSliderDiv.find('.add-to-bag');

                if (addToBagButton.hasClass('add-to-bag--add') && !addToBagButton.attr('disabled')) {
                    productCode = addToBagButton.parents('.js-stl-swatchinfo-wrap')
                        .find('.codeholder-dropdown:visible option:selected')
                        .val();
                } else if (selectedSliderSwatchContainer.length) {
                    productCode = activeSwatch.data('shopthelook-swatch-productcode');
                    colourCode = selectedSliderSwatchContainer.length ? activeSwatch.data('shopthelook-swatch-colourcode') : 1;
                    collectionGroupCode = activeSwatch.data('shopthelook-swatch-groupcode');
                } else {
                    selectedSliderSwatchContainer = selectedSliderDiv.find('.product-description');
                    activeSwatch = selectedSliderDiv.find('.product-description > div.js-stl-swatchinfo-wrap');


                    productCode = activeSwatch.data('shopthelook-swatchinfo-productcode');
                    colourCode = activeSwatch.data('shopthelook-swatchinfo-colourcode');
                    collectionGroupCode = activeSwatch.data('shopthelook-swatchinfo-groupcode');
                }
                divToSend = selectedSliderDiv;
            }

            if (!productCode) {
                productCode = window.universal_variable.product.id;
                colourCode = $('.SwatchContainer').find('li.active').attr('data-swatch-colourcode');
            }
            var payload = {
                productCode: productCode,
                colourCode: colourCode,
                collectionGroupCode: collectionGroupCode
            };
            if (selectedObject.hasClass('heart-empty')) {
                window.addToBagCommons.checkMaximumWishlistLimitReached(payload, divToSend);
            } else if (selectedObject.hasClass('heart-filled')) {
                var productEntry = window.addToBagCommons.isProductExistInWishlist(productCode, colourCode, collectionGroupCode);

                if (productEntry && productEntry.position) {
                    window.addToBagCommons.removeFromWishlist(productEntry.position, divToSend);
                } else {
                    // TODO: Error handling code will be added here later
                    /* eslint-disable  no-console */
                    console.log('Couldnt perform this operation. Something went wrong');

                    selectedObject.css('display', 'block');
                    selectedObject.next().css('display', 'none');
                }
            }
        });

        toggleNotifyMeFormOneSize();
        toggleWishlistIcon();
        toggleBackorderOneSize();

        return new DataHolder();
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
}());

/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals
 */

/*
 globals
 $
 define
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(factory);
    } else {
        // Browser globals
        root.addToBagButton = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    /**
     * Selector that describes actual add to bag button.
     * @type {string}
     */
    var selector = '.add-to-bag';

    /**
     * Classes that can change button status, key - state name, value - actual class that
     * should be added to element.
     * @type {{add: string, adding: string, added: string, error: string}}
     */
    var stateClasses = {
        add: 'add-to-bag--add',
        adding: 'add-to-bag--adding',
        added: 'add-to-bag--added',
        error: 'add-to-bag--error'
    };

    /**
     * Return wrapper for button instance with additional methods.
     * @param {(string|jQuery)} container
     * @return {{toggleDisabled: toggleDisabled, setState: setState, $button: jQuery}}
     */
    function getButton(container) {
        /**
         * jQuery object for current button.
         * @type {jQuery}
         */
        var $button = $(container).is(selector) ? $(container) : $(container).find(selector);

        /**
         * Disable or enable button based on flag value.
         * @param {boolean} flag
         */
        function toggleDisabled(flag) {
            $button.removeClass(Object.values(stateClasses).join(' '));
            $button.prop('disabled', flag);
            if (!flag) { $button.addClass(stateClasses.add); }
        }

        /**
         * Set button state.
         * @param state Class name from {@link stateClasses}
         */
        function setState(state) {
            toggleDisabled(false);
            $button.removeClass(Object.values(stateClasses).join(' '));
            $button.addClass(state);
        }

        return {
            toggleDisabled: toggleDisabled,
            setState: setState,
            $button: $button
        };
    }

    return {
        selector: selector,
        stateClasses: stateClasses,
        getButton: getButton
    };
}));

/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals
*/

/*
 globals
 define
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else {
        // Browser globals
        root.stringUtils = factory(window.Util);
    }
}(typeof self !== 'undefined' ? self : this, function (Util) {
    /**
     * Prepend prefix string to sting if the given string is not empty.
     *
     * @param str the string to be prepended
     * @param prefix the string to prepend
     * @returns {string} concatenated string
     */
    function prepend(str, prefix) {
        if (Util.isNotEmpty(str)) {
            return prefix + str;
        }
        return str;
    }

    return {
        prepend: prepend
    };
}));

/*
 eslint-disable
 no-restricted-globals,
 import/prefer-default-export
*/

const $ = window.jQuery;

/**
 * Required to prevent error on ios devices https://bugs.webkit.org/show_bug.cgi?id=156115
 *
 * @param {object} state Page state object
 * @param {string} title Page title
 * @param {string} url Page url
 * @return {boolean}
 */
const replaceState = $.throttle(650, function (state, title, url) {
    history.replaceState(state, title, url);
});

export { replaceState };

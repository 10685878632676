/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals,
 eqeqeq
*/

/*
 globals
 define
 Util
 stateService
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(factory);
    } else {
        // Browser globals
        root.tmplUtil = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    function isActiveSwatch(swatches, swatch, code) {
        if (stateService.hasProperty('swatch.' + code + '.active') && swatches.find(function (element) {
            return element.colorCode == stateService.getProperty('swatch.' + code + '.active');
        }) != null) {
            return stateService.getProperty('swatch.' + code + '.active') == swatch.colorCode;
        }
        if (swatch.active) {
            return stateService.setProperty('swatch.' + code + '.active', swatch.colorCode);
        }
        return swatch.active;
    }

    function getProductName(namenocolor, groupNameNoColor) {
        var productName = namenocolor;
        if (Util.isNotTrue(stateService.getProperty('config.isCollection')) && Util.isNotEmpty(groupNameNoColor)) {
            productName = groupNameNoColor;
        }
        return productName;
    }

    function getProductNameForImageUrl(namenocolor, groupNameNoColor) {
        var productName = getProductName(namenocolor, groupNameNoColor);

        if (Util.isNotEmpty(productName)) {
            productName = productName.toString().trim();
            productName = productName.replace(/[^a-zA-Z0-9 -]/g, '');
            productName = productName.replace(/ /g, '-');
        }

        if (Util.isNotEmpty(productName)) {
            productName += '/';
        }
        return productName;
    }

    return {
        isActiveSwatch: isActiveSwatch,
        getProductName: getProductName,
        getProductNameForImageUrl: getProductNameForImageUrl
    };
}));

/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals
*/

/*
 globals
 define
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else {
        // Browser globals
        root.responsiveUtils = factory(window.jQuery);
    }
}(typeof self !== 'undefined' ? self : this, function ($) {
    var mediaType = '';
    var resizeCallbacks = new Set();

    /**
     * Detects current layout by page width and orientation.
     * @returns {string} Current layout type for image presets.
     */
    function getMediaType() {
        if (window.matchMedia('(min-width: 1280px)').matches) {
            return 'desktop';
        }

        if (window.matchMedia('(min-width: 768px) and (max-width: 1279px) and (orientation: landscape)').matches) {
            return 'tabletLandscape';
        }

        if (window.matchMedia('(min-width: 768px) and (max-width: 1279px) and (orientation: portrait)').matches) {
            return 'tabletPortrait';
        }

        return 'mobile';
    }

    $(window).load(function () {
        mediaType = getMediaType();
    });
    $(window).resize($.debounce(200, function checkResize() {
        var currentMediaType = getMediaType();
        if (currentMediaType !== mediaType) {
            mediaType = currentMediaType;
            resizeCallbacks.forEach(function triggerCallbacks(callback) {
                callback.call(undefined, mediaType);
            });
        }
    }));

    /**
     * Add callback on media type change event.
     * @param {function} callback Callback to be executed on layout change.
     */
    function onMediaTypeChanged(callback) {
        resizeCallbacks.add(callback);
    }

    /**
     * Remove callback on media type change event.
     * @param {function} callback Function to be removed from list of executed.
     * @example
     * function clb() { console.log("resized"); }
     * offMediaTypeChanged(clb);
     * @returns {undefined}
     */
    function offMediaTypeChanged(callback) {
        resizeCallbacks.delete(callback);
    }

    /**
     * Check if the current media type is mobile.
     * @returns {boolean} true if mobile
     */
    function isMobile() {
        return getMediaType() === 'mobile';
    }

    return {
        getMediaType: getMediaType,
        onMediaTypeChanged: onMediaTypeChanged,
        offMediaTypeChanged: offMediaTypeChanged,
        isMobile: isMobile
    };
}));

/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals
*/

/*
 globals
 define
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(factory);
    } else {
        // Browser globals
        root.plpLandscape = factory(window.responsiveUtils, window.jQuery, window._);
    }
}(typeof self !== 'undefined' ? self : this, function (responsiveUtils, $, _) {
    function stickyFilter() {
        if (responsiveUtils.isMobile()) {
            var stickyContainer = $('#m-marker');
            var stickyContainerHeight = stickyContainer.height();
            var stickyElement = stickyContainer.children();
            var stickyContainerPosition = stickyContainer.length > 0 ? stickyContainer.offset().top : null;
            var headerHeight = $('.main-header').innerHeight();

            $(window).on('scroll', function () {
                if (stickyContainer.length > 0) {
                    var stickyPosition = stickyContainerPosition - ($(window).scrollTop() + headerHeight);
                    if (stickyPosition < 0) {
                        stickyElement.addClass('sticky').css('top', headerHeight);
                        stickyContainer.css('min-height', stickyContainerHeight);
                    } else {
                        stickyElement.removeClass('sticky').css('top', '');
                        stickyContainer.css('min-height', '');
                    }
                }
            });
        }
    }

    /**
     * Initialize or update product images
     */
    function updateImageCarousels(rootElement) {
        var parent = $(rootElement || document);
        var $desktop = parent.find('.product-image-desktop');
        var $mobile = parent.find('.product-image-mobile');

        if (responsiveUtils.isMobile()) {
            $desktop.hide();
            $mobile.show();

            $mobile.each(function () {
                if ($(this).hasClass('slick-initialized')) {
                    $(this).slick('setPosition');
                } else {
                    $(this).slick({
                        arrows: false,
                        dots: true,
                        infinite: false,
                        speed: 300,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    });
                }
            });
        } else {
            $desktop.show();
            $mobile.hide();

            $mobile.each(function () {
                if ($(this).hasClass('slick-initialized')) {
                    $(this).slick('unslick');
                }
            });
        }
    }

    /**
     * Process event when page is ready and screen resolution has changed
     */
    function onUpdateImageCarousels() {
        updateImageCarousels('#results');
    }

    /**
     * Extract image url from product.
     *
     * @param {Object} product
     * @param {string} field
     * @param {*} value
     * @return {string} Image url or empty string.
     */
    function extractImageData(product, field, value) {
        var imageUrl = '';
        if (_.result(product, 'imagesNp', []) !== null) {
            var image = _.result(product, 'imagesNp', []).filterFirst(field, value);
            imageUrl = _.result(image, 'url', '');
        }
        return imageUrl;
    }

    /**
     * Initialize product images when page is ready.
     */
    $(function () {
        $('#results').on('data_renderred', onUpdateImageCarousels);
        $(window).on('load resize', stickyFilter);
    });

    /**
     * Listen for changes in media type (screen resolution)
     */
    responsiveUtils.onMediaTypeChanged(onUpdateImageCarousels);

    return {
        updateImageCarousels: updateImageCarousels,
        extractImageData: extractImageData
    };
}));

/*
 eslint-disable
 no-param-reassign,
 no-extend-native,
 no-restricted-globals,
 no-restricted-syntax,
 consistent-return,
 eqeqeq
*/

/*
 globals
 $
*/

Array.prototype.filterFirst = function (field, value) {
    if (this instanceof Array) {
        if (value instanceof Array) {
            for (var i = 0; i < value.length; i++) {
                for (var j = 0; j < this.length; j++) {
                    if (this[j][field] == value[i]) {
                        return this[j];
                    }
                }
            }
        } else {
            for (var k = 0; k < this.length; k++) {
                if (this[k][field] == value) {
                    return this[k];
                }
            }
        }
    }
    return {};
};


Array.prototype.chunkSplit = function (chunksize) {
    var result = [];
    var i;
    for (i = 0; i < this.length; i += chunksize) {
        result.push(this.slice(i, i + chunksize));
    }
    return result;
};

Array.prototype.filterList = function (field, value) {
    var result = [];
    for (var i = 0; i < this.length; i++) {
        if (this[i][field] == value) {
            result.push(this[i]);
        }
    }
    return result;
};

Array.prototype.intersect = function (thisField, arg, argField) {
    var intersection = [];
    var arr1Length = this.length;
    var arr2Length = arg.length;

    for (var i = 0; i < arr1Length; i++) {
        for (var j = 0; j < arr2Length; j++) {
            var thisVal = null;
            var argVal = null;
            if (thisField != null) {
                thisVal = this[i][thisField];
            } else {
                thisVal = this[i];
            }

            if (argField != null) {
                argVal = arg[j][argField];
            } else {
                argVal = arg[j];
            }

            if ($.isArray(thisVal) && $.isArray(argVal)) {
                if (thisVal.intersect(null, argVal, null).length != 0) {
                    intersection.push(this[i]);
                }
            } else if ($.isArray(thisVal)) {
                if (thisVal.intersect(null, [argVal], null).length != 0) {
                    intersection.push(this[i]);
                }
            } else if ($.isArray(argVal)) {
                if ([thisVal].intersect(null, argVal, null).length != 0) {
                    intersection.push(this[i]);
                }
            } else if (thisVal == argVal) {
                intersection.push(this[i]);
            }
        }
    }
    return intersection;
};

var Util = {};

Util.getMediaType = function () {
    if (window.matchMedia('(min-width: 1280px)').matches) {
        return 'desktop';
    } if (window.matchMedia('(min-width: 768px) and (max-width: 1279px) and (orientation: landscape)').matches) {
        return 'tabletLandscape';
    }
    if (window.matchMedia('(min-width: 768px) and (max-width: 1279px) and (orientation: portrait)').matches) {
        return 'tabletPortrait';
    }
    if (window.matchMedia('(max-width: 767px)').matches) {
        return 'mobile';
    }
};

Util.addPreset = function (input, suffix, preset) {
    input = Util.defaultIfNull(input, '').trim();
    suffix = Util.defaultIfNull(suffix, '').trim();
    preset = Util.defaultIfNull(preset, '').trim();

    if (Util.isNotEmpty(input)) {
        if (Util.isNotEmpty(suffix)) {
            input = input + '_' + suffix;
        }
        if (Util.isNotEmpty(preset)) {
            input = input + '?$' + preset + '$';
        }
        return input;
    }
    return '';
};

Util.isArray = function (a) {
    return (!!a) && (a.constructor === Array);
};

Util.isObject = function (a) {
    return (!!a) && (a.constructor === Object);
};

Util.isEmpty = function (arg) {
    if (Util.isObject(arg)) {
        return arg == null || Object.keys(arg).length == 0;
    }
    return arg == null || arg.length == 0;
};

Util.isNotEmpty = function (arg) {
    return Util.isEmpty(arg) == false;
};

Util.projection = function (object, fields) {
    var result = {};
    fields.forEach(function (field) {
        result[field] = object[field];
    });
    return result;
};

Util.isTrue = function (arg) {
    return arg === true || arg === 'true';
};

Util.isFalse = function (arg) {
    return arg === false || arg === 'false';
};

Util.isNotTrue = function (arg) {
    return !Util.isTrue(arg);
};

Util.isNotFalse = function (arg) {
    return !Util.isFalse(arg);
};

Util.getQueryParam = function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]?' + name + '(=([^&#]*)|&|#|$)');
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

Util.replaceQueryParam = function (url, paramName, paramValue) {
    var pattern = new RegExp('\\b(' + paramName + '=).*?(&|$)');
    if (url.search(pattern) >= 0) {
        return url.replace(pattern, '$1' + paramValue.toString().replace(/ /g, '+') + '$2');
    }
    var newUrl = url.replace(/\?$/, '');
    newUrl = newUrl + (newUrl.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
    return newUrl;
};

Util.defaultIfNull = function (val, defaultVal) {
    if (val != null) {
        return Util.cast(val);
    }
    return Util.cast(defaultVal);
};

Util.defaultIfNegativeOrNull = function (value, defaultValue) {
    var number = parseInt(value, 10);
    if (isNaN(number) || number < 0) {
        return defaultValue;
    }
    return number;
};

Util.defaultIfEmpty = function (val, defaultVal) {
    if (Util.isNotEmpty(val)) {
        return Util.cast(val);
    }
    return Util.cast(defaultVal);
};

Util.addIfNotEmpty = function (obj, key, value) {
    if (Util.isNotEmpty(value)) {
        obj[key] = value;
    }
};

Util.increment = function (obj, key) {
    if (Util.isNotEmpty(obj[key])) {
        obj[key] += 1;
    }
};

Util.decrement = function (obj, key) {
    if (Util.isNotEmpty(obj[key])) {
        obj[key] -= 1;
    }
};

Util.deepCopy = function (val) {
    return JSON.parse(JSON.stringify(val));
};

Util.cast = function (val) {
    if ($.isNumeric(val)) {
        return Number(val);
    }
    return val;
};

Util.guid = function () {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

Util.set = function () {
    function Set() {
        this.dataHolder = {};

        Set.prototype.add = function (value) {
            if (Util.isNotEmpty(value)) {
                this.dataHolder[value.toUpperCase()] = null;
            }
        };

        Set.prototype.remove = function (value) {
            if (Util.isNotEmpty(value)) {
                delete this.dataHolder[value.toUpperCase()];
            }
        };

        Set.prototype.contains = function (value) {
            if (Util.isNotEmpty(value)) {
                return value.toUpperCase() in this.dataHolder;
            }
        };

        Set.prototype.values = function () {
            return Object.keys(this.dataHolder);
        };
    }

    return new Set();
};

Util.fixScroll = function (fix) {
    if (fix) {
        $('body').attr('data-scroll-top', $(window).scrollTop());
        $('body').addClass('twc-modal-open');
    } else {
        $('body').removeClass('twc-modal-open');
        $(window).scrollTop($('body').attr('data-scroll-top'));
    }
};

Util.isTouch = function () {
    return 'ontouchstart' in window
        || navigator.maxTouchPoints;
};

Util.isMobile = function () {
    return Util.getMediaType() === 'mobile';
};

Util.isDesktop = function () {
    return Util.getMediaType() === 'desktop';
};

Util.getTouchPoint = function (event) {
    if (event != null && event.originalEvent != null && Util.isNotEmpty(event.originalEvent.changedTouches)) {
        return {
            x: event.originalEvent.changedTouches[0].clientX,
            y: event.originalEvent.changedTouches[0].clientY
        };
    }
    return {};
};

Util.getProperty = function (object, key) {
    var splittedKey = key.split('.');
    var current = object;
    for (var i = 0; i < splittedKey.length; i++) {
        if (current != null) {
            current = current[splittedKey[i]];
        } else {
            return null;
        }
    }
    return Util.cast(current);
};

Util.cleanObject = function (obj) {
    var isArray = obj instanceof Array;
    for (var k in obj) {
        if (Util.isEmpty(obj[k])) {
            if (isArray) {
                obj.splice(k, 1);
            } else {
                delete obj[k];
            }
        } else if (typeof obj[k] === 'object') {
            Util.cleanObject(obj[k]);
        }
    }
};

/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals
*/

/*
 globals
 define
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else {
        // Browser globals
        root.arrayUtils = factory(window.Util);
    }
}(typeof self !== 'undefined' ? self : this, function (Util) {
    /**
     * Get string element by index. If the index is out of range then return default value.
     *
     * @param array the array
     * @param index the index of element in array
     * @param defaultVal the value retuned in case of index is out of range
     * @returns {object} element from array or default value
     */
    function get(array, index, defaultVal) {
        var isNotEmptyArray = Util.isArray(array) && Util.isNotEmpty(array);
        var isValidIndex = !Number.isNaN(index) && index >= 0;
        if (isNotEmptyArray && isValidIndex && array.length > index) {
            return array[index];
        }
        return defaultVal;
    }

    return {
        get: get
    };
}));

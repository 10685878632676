/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals,
*/

/*
 globals
 define
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else {
        // Browser globals
        root.responsiveUtils = factory(window.jQuery);
    }
}(typeof self !== 'undefined' ? self : this, function ($) {
    $(document).ready(function initializeScrollToTop() {
        function scrollTopListener() {
            if (window.pageYOffset >= window.innerHeight) {
                $('.scroll-top').fadeIn(300);
            } else {
                $('.scroll-top').fadeOut(300);
            }
        }

        $(window).scroll($.debounce(100, scrollTopListener));
        $(window).resize($.debounce(300, scrollTopListener));

        $('.scroll-top').click(function () {
            $(window).off('scroll', scrollTopListener);

            $('html,body')
                .stop(true, false)
                .animate({
                    scrollTop: 0
                },
                'slow',
                function () {
                    $(window).scroll(scrollTopListener).scroll();
                });
        });
    });
}));

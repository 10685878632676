// Extend jQuery template with local variable declaration
$.extend($.tmpl.tag, {
    "var": {
        open: "var $1;"
    },
    "setvar": {
        open: "$2 = $1;"
    },
    "eval": {
        open: "$1;"
    },
    "for": {
        _default: {$2: "var i=1;i<=1;i++"},
        open: 'for ($2){',
        close: '};'
    }
});

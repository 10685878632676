/*
 eslint-disable
 eqeqeq
*/

/*
 globals
 Util
 stateService
*/

function PresetService() {}

PresetService.prototype.getPresetKey = function () {
    throw new Error('Please use concreate implementation');
};


function PlpPresetService() {}

PlpPresetService.prototype = new PresetService();

PlpPresetService.prototype.getPresetKey = function () {
    var mediaType = Util.getMediaType();
    var suffix = '';
    var type = stateService.getOrCreate('config.mobile.view', 'TWO_ACROSS');

    if (mediaType != 'mobile') {
        return mediaType;
    }

    if (type == 'ONE_ACROSS') {
        suffix = 'One';
    } else if (type == 'TWO_ACROSS') {
        suffix = 'Two';
    }

    return mediaType + suffix;
};


function PdpPresetService() {}

PdpPresetService.prototype = new PresetService();

PdpPresetService.prototype.getPresetKey = function () {
    return Util.getMediaType();
};

/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals,
 no-global-assign,
 no-shadow,
 no-unused-vars,
 no-plusplus,
 eqeqeq
*/

/*
 globals
 $
 _
 jQuery
 Util
 ACC
 imageService
 timeoutId
 RecommendationHelper
 syncService
 SyncService
 presetService
 PdpPresetService
 imageService
 ImageService
 AddToBagTileGroupCollectionFragranceHelper
 AddToBagTileGroupCollectionHelper
 AddToBagCollectionHelper
 AddToBagBaseHelper
 currentSwatch
 PinchZoomer
 vanillaFetch
 toggleTileGroupCollectionWishlistIcon
*/

var pdpStatus = {};

// TODO: decommissioning_header remove old overlay
// delay interval before the product quantity gets updated; see add-to-bag-snackbar: overlayAnimationTime + overlayAnimationDelay
var addToBagSnackbarShowingTime = 750;

function getElementCoords(selector) {
    var box = document.querySelector(selector).getBoundingClientRect();

    return {
        top: box.top + window.pageYOffset,
        left: box.left + window.pageXOffset
    };
}

function scrollToElement(selector, offset) {
    window.scroll({
        top: getElementCoords(selector).top + offset,
        left: 0,
        behavior: 'smooth'
    });
}

function resetImageViewer() {
    if (pdpStatus.selectedSwatchColour === null) {
        var selectedSwatch = $('.swatch.active').data('swatch-colour');
        pdpStatus.selectedSwatchColour = selectedSwatch !== undefined ? selectedSwatch : null;
    }

    $('.carousel-product-images[data-swatch-info="' + pdpStatus.selectedSwatchColour + '"]').slick('slickGoTo', 0);
    $('.carousel-thumbs[data-swatch-info="' + pdpStatus.selectedSwatchColour + '"]').slick('slickGoTo', 0);
}

function stopVideo() {
    var videoPlayers = document.querySelectorAll('.product-video__video-player');

    videoPlayers.forEach(function (videoPlayer) {
        videoPlayer.pause();
        videoPlayer.currentTime = 0;
    });
}

function hideVideoPlayer() {
    var productVideo = document.querySelectorAll('.product-video');
    var mainProductImage = document.querySelectorAll('.product-image__wrapper');
    var mainProductImageMobile = document.getElementById('productImageMobile');
    var resizeEvent = document.querySelector('[data-resize-event]');

    stopVideo();

    if (resizeEvent) {
        var resizeEventValue = resizeEvent.getAttribute('data-resize-event');

        if (resizeEventValue === 'true') {
            var mainImgMobile = mainProductImageMobile.querySelector('.slick-track');
            mainImgMobile.classList.remove('product-image--hide');
            mainImgMobile.setAttribute('data-resize-event', 'false');
        }
    }

    if (mainProductImageMobile) {
        var mainSlickTrack = mainProductImageMobile.querySelectorAll('.slick-track');

        mainSlickTrack.forEach(function (slickTreack) {
            slickTreack.classList.remove('product-image--hide');
        });
    }

    mainProductImage.forEach(function (productImage) {
        productImage.classList.remove('product-image--hide');
    });

    productVideo.forEach(function (video) {
        video.classList.add('product-video--hide');
        video.setAttribute('data-video-is-hidden', 'true');
    });
}

function videoExitFullScreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
    }
}

function mobileVideoExit(event) {
    var videoPlayerId = event.target.getAttribute('data-video-id');
    var video = document.getElementById(videoPlayerId);

    video.addEventListener('webkitendfullscreen', function () {
        resetImageViewer();
        hideVideoPlayer();
    }, false);
}

function loopVideo(videoPlayer) {
    var shouldLoop = true;
    var productImage = document.querySelectorAll('.product-image__wrapper .slick-slide');
    var productImageSize = productImage[0].style.width;
    var windowSize = document.body.offsetWidth;

    videoPlayer.addEventListener('ended', function () {
        if (shouldLoop) {
            videoPlayer.play();
            shouldLoop = false;
        } else {
            resetImageViewer();

            if (document.fullscreenElement != null
                || document.mozFullScreenElement != null
                || document.webkitFullscreenElement != null
                || document.msFullscreenElement != null) {
                videoExitFullScreen();
            }

            productImage.forEach(function (image) {
                image.style.width = productImageSize;
            });

            if (windowSize <= 767) {
                if (document.fullscreenElement != null
                    || document.mozFullScreenElement != null
                    || document.msFullscreenElement != null) {
                    hideVideoPlayer();
                }
            } else {
                hideVideoPlayer();
            }

            videoPlayer.currentTime = 0;
            shouldLoop = true;
        }
    });
}

function fullScreenDetect() {
    var fullScreen = true;

    document.addEventListener('fullscreenchange', function () {
        if (fullScreen) {
            fullScreen = false;
        } else {
            resetImageViewer();
            hideVideoPlayer();
            fullScreen = true;
        }
    });
}

function videoToFullScreen(videoPlayer) {
    if (videoPlayer.requestFullscreen) {
        videoPlayer.requestFullscreen();
    } else if (videoPlayer.mozRequestFullScreen) { /* Firefox */
        videoPlayer.mozRequestFullScreen();
    } else if (videoPlayer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        videoPlayer.webkitRequestFullscreen();
    } else if (videoPlayer.msRequestFullscreen) { /* IE/Edge */
        videoPlayer.msRequestFullscreen();
    }

    fullScreenDetect();
}

function playVideo(videoId) {
    var videoPlayer = document.getElementById(videoId);
    var windowSize = document.body.offsetWidth;

    if (windowSize <= 767) {
        videoToFullScreen(videoPlayer);
    }

    videoPlayer.play();
    loopVideo(videoPlayer);
}

function displayVideoPlayer(e) {
    var videoPlayer = e.target.getAttribute('data-video-id');
    var productVideo = document.querySelector('[data-video-id="' + videoPlayer + '"]');
    var mainProductImage = document.querySelectorAll('.product-image__wrapper');
    var mainProductImageMobile = document.getElementById('productImageMobile');

    if (mainProductImageMobile) {
        var mainSlickTrack = mainProductImageMobile.querySelectorAll('.slick-track');

        mainSlickTrack.forEach(function (slickTrack) {
            slickTrack.classList.add('product-image--hide');
        });
    }

    mainProductImage.forEach(function (productImage) {
        productImage.classList.add('product-image--hide');
    });

    productVideo.classList.remove('product-video--hide');
    productVideo.setAttribute('data-video-is-hidden', 'false');

    playVideo(videoPlayer);
}

function removeThumbnailBorder() {
    var currentThumb = document.querySelectorAll('.thumb-clicked');
    if (currentThumb) {
        currentThumb.forEach(function (thumb) {
            thumb.classList.remove('thumb-clicked');
        });
    }
}

function videoIconOnClick() {
    var videoIcon = document.querySelectorAll('.product-video-icon__icon');

    if (videoIcon) {
        videoIcon.forEach(function (icon) {
            icon.addEventListener('click', function (e) {
                displayVideoPlayer(e);
                removeThumbnailBorder();
                mobileVideoExit(e);
            });
        });
    }
}

function checkIfVideoIsHidden() {
    var productVideo = document.querySelectorAll('.product-video');

    productVideo.forEach(function (video) {
        var videoIsHidden = video.getAttribute('data-video-is-hidden');
        if (videoIsHidden === 'false') {
            hideVideoPlayer();
        }
    });
}

function hideVideoPlayerMobile() {
    var slickDots = document.querySelectorAll('.slick-dots');

    slickDots.forEach(function (dots) {
        dots.addEventListener('click', function () {
            var mobileImageViewer = document.getElementById('productImageMobile');
            var mainImgMobile = mobileImageViewer.querySelector('.slick-track');

            hideVideoPlayer();
            mainImgMobile.style.opacity = 1;
        });
    });
}

function getThumbnailsToDisplay(thumbsToShow) {
    var imageDimension = window.Header.currentPageId;

    if (thumbsToShow <= 4) {
        return thumbsToShow;
    }

    if (imageDimension.indexOf('Portrait') !== -1 && thumbsToShow > 5) {
        thumbsToShow = 6;
        return thumbsToShow;
    }

    thumbsToShow = 5;
    return thumbsToShow;
}

function isCollectionProduct() {
    return $('#isCollectionProduct').val() === 'true';
}

function isTileGroupCollectionTemplate() {
    var collectionType = $('#collectionType').val();
    return isCollectionProduct() && collectionType === ACC.config.collectionType.tileGroupCollection;
}

function isTileGroupCollectionGroupTemplate() {
    var collectionType = $('#collectionType').val();
    return isCollectionProduct() && collectionType === ACC.config.collectionType.tileGroupCollectionGroup;
}

function isTileGroupCollectionFragranceTemplate() {
    var collectionType = $('#collectionType').val();
    return isCollectionProduct() && collectionType === ACC.config.collectionType.tileGroupCollectionFragrance;
}

function getSizeOneAndSizeTwoFromUrl() {
    var params = new URLSearchParams(window.location.search);
    var sizeOne = params.get('sizeOne');
    var sizeTwo = params.get('sizeTwo');

    if (!sizeOne || (sizeTwo && !sizeOne)) {
        return null;
    }

    return { sizeOne: sizeOne, sizeTwo: sizeTwo };
}

document.addEventListener('DOMContentLoaded', function () {
    videoIconOnClick();
    hideVideoPlayerMobile();
});

$(document).ready(function () {
    var selectpickers = $('.selectpicker');
    selectpickers.selectpicker();
    $('.add-to-bag').removeClass('hide');
    $('.select-qty').removeClass('hide');

    $('.button-shop-now').on('click', function () {
        scrollToElement('.egiftcard-form', -10);
    });
    $('.button-shop-now-mobile').on('click', function () {
        scrollToElement('.egiftcard-form', -document.querySelector('.header').clientHeight - 10);
    });

    var result = getSizeOneAndSizeTwoFromUrl();

    if (!result) {
        return;
    }

    var sizeOne = result.sizeOne;
    var sizeTwo = result.sizeTwo;

    var sizeOneSelector = null;
    var sizeTwoSelector = null;

    // Preselect sizes
    selectpickers.on('loaded.bs.select', function (e) {
        var target = e.target;
        var jQueryTarget = $(target);
        var arrayOptions = Array.from(target.options);
        var isQtySelect = arrayOptions.some(function (option) {
            return option.dataset.content === 'Qty';
        });

        if (isQtySelect) {
            return;
        }

        arrayOptions.forEach(function (option) {
            var dataset = option.dataset;
            var isUnavailable = dataset.availability === 'Sold Out' && dataset.notifyme !== 'true';
            var sku = dataset.sku;
            var value = option.value;

            if (!isUnavailable && sizeOne && (sku === sizeOne || value === sizeOne)) {
                jQueryTarget.val(value);
                jQueryTarget.trigger('change');
                sizeOneSelector = target;
                window.isSizePreselectingHandled = true;
            }

            if (!isUnavailable && sizeTwo && (sku === sizeTwo || value === sizeTwo)) {
                jQueryTarget.val(value);
                jQueryTarget.trigger('change');
                sizeTwoSelector = target;
                window.isSizePreselectingHandled = true;
            }
        });

        if (window.isSizePreselectingHandled) {
            selectpickers.on('changed.bs.select', function (e) {
                if (!window.isSizePreselectingHandled) {
                    return;
                }

                var target = e.target;
                var arrayOptions = Array.from(target.options);
                var selectedValue = $(target).val();

                var currentOption = arrayOptions.find(function (option) {
                    return option.value === selectedValue;
                });

                var isSizeOne = sizeOneSelector === target;
                var isSizeTwo = sizeTwoSelector === target;

                if (!isSizeOne && !isSizeTwo) {
                    return;
                }

                var sku = currentOption.dataset.sku || '';
                var value = currentOption.dataset.value || '';
                var isEmptyOption = !(sku || value);

                var basePath = '' + window.location.origin + window.location.pathname;
                var params = new URLSearchParams(window.location.search);

                if (isSizeOne) {
                    params.delete('sizeTwo');

                    if (isEmptyOption) {
                        params.delete('sizeOne');
                    } else {
                        params.set('sizeOne', sku || value);
                    }
                } else if (isEmptyOption) {
                    params.delete('sizeTwo');
                } else {
                    params.set('sizeTwo', sku || value);
                }

                window.historyproxy.replaceState(
                    null,
                    document.title,
                    basePath + '?' + params.toString()
                );
            });
        }
    });
});

function slickSetPositionAndGoTo(selector, setPosition, goTo) {
    var jqSelector = null;
    if (selector instanceof jQuery) {
        jqSelector = selector;
    } else {
        jqSelector = $(selector);
    }

    jqSelector.each(function () {
        var slideIndex = pdpStatus.swatchGalleryIndex[$(this).attr('colour')];
        if (Util.isTrue(goTo) && slideIndex != null) {
            $(this).slick('slickGoTo', slideIndex, true);
        }
        if (Util.isTrue(setPosition)) {
            $(this)
                .slick('setPosition')
                .slick('setPosition');
        }
    });
}

function showSelectedRecommendations(swatchColour) {
    $('[data-recommendation][data-swatch-info]').addClass('hide');

    var carouselSelector = "[data-recommendation][data-swatch-info='" + swatchColour + "']";
    $(carouselSelector).removeClass('hide');

    if ($(carouselSelector).hasClass('slick-initialized')) {
        // Refresh positioning of slick implicitly redraw it
        $(carouselSelector).slick('setPosition');
    } else {
        // Initialize carousel
        $(carouselSelector).slick({
            infinite: false,
            dots: false,
            slidesToShow: 7,
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        dots: false
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });
    }
}

// This method is invoked during initialization, so manual recommendations are created
function initManualRecommendations() {
    // Init recommendation template
    var tmplItem = $('#recommendationTemplate')
        .tmpl({
            colours: RecommendationHelper.getInstance().getRecommendations()
        })
        .appendTo('#section-viewed')
        .tmplItem();
    // Select recommendations of active colour
    showSelectedRecommendations(RecommendationHelper.getInstance().getInitialSwatchColour());
    $(window).trigger('recommendationsUpdateFinished', { type: 'manual' });
}

function toggleMobileScroller() {
    var selectpicker = $('.selectpicker, .js-stl-selectpicker');
    if (Util.isMobile()) {
        selectpicker.addClass('mobile-device');
        selectpicker.attr('data-mobile', 'true');
    } else {
        selectpicker.removeClass('mobile-device');
        selectpicker.attr('data-mobile', 'false');
    }
}

function slickTrackHeightInterval(thumbs, minHeight) {
    var displayThumbs = setInterval(function () {
        var slickTrackHeight = document.querySelector('#product-thumbnails .slick-track').style.height;
        var videoIcon = document.querySelector('.product-video-icon');

        if (parseInt(slickTrackHeight, 10) > minHeight) {
            thumbs.style.opacity = 1;
            clearInterval(displayThumbs);
        }
    }, 250);
}

function thumbnailDisplayDelay() {
    var thumbs = document.getElementById('product-thumbnails');
    thumbs.style.opacity = 0;
    var thumbnails = document.querySelectorAll('#product-thumbnails .slick-track .slick-slide');

    if (thumbnails.length > 2) {
        slickTrackHeightInterval(thumbs, 150);
    } else {
        slickTrackHeightInterval(thumbs, 100);
    }
}

function thumbnailBorderOnload() {
    var firstSlide = document.querySelectorAll('[data-slick-index="0"]');
    firstSlide.forEach(function (slide) {
        slide.classList.add('thumb-clicked');
    });
}

function setPinchZoomerWrapperHeight() {
    var mainImage = $('div[data-elem=pinchzoomer] > .img-main').outerHeight();
    var pinchZoomerWrapper = $('.pinchzoomer__wrapper');
    pinchZoomerWrapper.css('min-height', mainImage + 'px');
}

function resetImgViewerWhileVideoIsActive() {
    var productVideo = document.querySelectorAll('.product-video');

    if (productVideo.length > 0) {
        productVideo.forEach(function (video) {
            var videoIsHidden = video.getAttribute('data-video-is-hidden');
            if (videoIsHidden === 'false') {
                resetImageViewer();
            }
        });
    }
}

function initImageCarousels(rootElement) {
    var parent = $(rootElement || document);
    // restore after slick breakpoint
    $(window).on('orientationchange resize', function () {
        setPinchZoomerWrapperHeight();
        pdpStatus.preventGalleryChange = true;
        setTimeout(function () {
            pdpStatus.preventGalleryChange = false;
            slickSetPositionAndGoTo('.carousel-thumbs, .carousel-product-images', true, true);
        }, 250);
    });

    function hideWhenVideoActive() {
        var mobileImageViewer = document.getElementById('productImageMobile');
        var productVideo = document.querySelector('.product-video');
        var mainImgMobile = mobileImageViewer.querySelector('.slick-track');

        if (productVideo) {
            var videoIsHidden = productVideo.getAttribute('data-video-is-hidden');
            if (videoIsHidden === 'true') {
                mainImgMobile.style.opacity = 1;
            } else {
                mainImgMobile.classList.add('product-image--hide');
                mainImgMobile.setAttribute('data-resize-event', 'true');
            }
        }
    }

    function thumbSelectData(index) {
        var modalLink = document.querySelectorAll('.modal-link-large');
        modalLink.forEach(function (modal) {
            modal.setAttribute('data-slick-index', index);
        });
    }

    function slickDotsClickEvent() {
        var slickDots = document.querySelectorAll('.slick-dot');

        slickDots.forEach(function (dot) {
            dot.addEventListener('click', function (e) {
                e.preventDefault();
                var slickIndex = $(this).data('slickIndex');
                thumbSelectData(slickIndex);
            });
        });
    }

    $('.carousel-product-images').on('init', function () {
        slickDotsClickEvent();
    });

    $('.carousel-product-images').on('reInit', function () {
        slickDotsClickEvent();
    });

    parent.find('.carousel-product-images').each(function () {
        $(this)
            .slick({
                arrows: false,
                dots: true,
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: 1,
                mobileFirst: true,
                waitForAnimate: false,
                fade: false,
                accessibility: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            dots: true,
                            infinite: true,
                        }
                    }
                ]
            })
            .on(
                'beforeChange',
                function (event, slick, currentSlide, nextSlide) {
                    hideVideoPlayerMobile();
                    if (pdpStatus.preventGalleryChange) {
                        event.stopPropagation();
                        event.preventDefault();
                    } else {
                        pdpStatus.swatchGalleryIndex[$(this).attr('colour')] = nextSlide;
                    }
                }.bind(this)
            );

        $(this).on('breakpoint', function () {
            var mobileImageViewer = document.getElementById('productImageMobile');

            if (mobileImageViewer) {
                var mainImgMobile = mobileImageViewer.querySelector('.slick-track');

                mainImgMobile.style.opacity = 0;

                setTimeout(function () {
                    hideWhenVideoActive();
                }, 750);
            }
        });

        var clonedSlicks = $(this).find('.slick-cloned > .pinchzoomer__wrapper');

        if (clonedSlicks.length > 0 && clonedSlicks.css('opacity') === '0') {
            clonedSlicks.css('opacity', '1');
        }
    });

    $('.carousel-thumbs').on('click', '.slick-slide', function () {
        var actIndex = $(this).attr('data-slick-index');
        var slideParent = $(this).parents();
        var swatchColour = $(slideParent[2]).attr('colour');
        var productImg = document.querySelector('.carousel-product-images[colour="' + swatchColour + '"]');

        // Toggle black border around thumbs
        $(this)
            .addClass('thumb-clicked')
            .siblings()
            .removeClass('thumb-clicked');
        thumbSelectData(actIndex);

        productImg.slick.slickGoTo(Number(actIndex));

        checkIfVideoIsHidden();
    });

    $('.carousel-thumbs-lg').on('click', '.slick-slide', function () {
        var actIndex = $(this).attr('data-slick-index');
        var slideParent = $(this).parents();
        var swatchColour = $(slideParent[2]).attr('colour');
        var productImages = $(slideParent).find('.carousel-product-images-lg');

        $(productImages).each(function () {
            var track = $(this).find('[colour="' + swatchColour + '"]');
            track.context.slick.slickGoTo(Number(actIndex));
        });

        $(this)
            .addClass('thumb-clicked')
            .siblings()
            .removeClass('thumb-clicked');
    });

    $('.carousel-thumbs').on('init', function () {
        thumbnailDisplayDelay();
    });

    parent.find('.carousel-thumbs').each(function () {
        var thumbNumber = $(this).data('thumb-image-number');
        $(this)
            .slick({
                arrows: true,
                dots: false,
                speed: 300,
                adaptiveHeight: true,
                focusOnSelect: false,
                slidesToShow: getThumbnailsToDisplay(thumbNumber),
                slidesToScroll: 1,
                vertical: true,
                infinite: false,
                waitForAnimate: false,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            arrows: true,
                            dots: false,
                            speed: 300,
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            infinite: false,
                            waitForAnimate: false,
                            vertical: false
                        }
                    }
                ]
            })
            .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                if (pdpStatus.preventGalleryChange) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            });
    });

    parent.find('.carousel-product-images-lg').each(function () {
        $(this).slick({
            arrows: false,
            dots: false,
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 1,
            waitForAnimate: false,
            fade: true
        });
    });

    parent.find('.carousel-thumbs-lg').each(function () {
        $(this).slick({
            arrows: true,
            dots: false,
            speed: 300,
            adaptiveHeight: true,
            focusOnSelect: false,
            slidesToShow: 7,
            slidesToScroll: 1,
            vertical: true,
            infinite: false,
            waitForAnimate: false,
            centerMode: false
        });
    });

    thumbnailBorderOnload();

    function modalThumbSelect(modalLink) {
        var modalThumbsLg = parent.find(".carousel-thumbs-lg[colour='" + $(modalLink).attr('colour') + "']");
        var thumbs = $(".carousel-thumbs[colour='" + $(modalLink).attr('colour') + "']").find('.thumb-clicked');
        var slickIndex = $(thumbs[0]).attr('data-slick-index') || 0;

        modalThumbsLg.find('.thumb-clicked').removeClass('thumb-clicked');
        modalThumbsLg.find("[data-slick-index='" + slickIndex + "']").addClass('thumb-clicked');
        resetImgViewerWhileVideoIsActive();
        hideVideoPlayer();
    }

    parent.find('.modal-link-large').each(function () {
        $(this).click(function (e) {
            e.preventDefault();
            stopVideo();

            modalThumbSelect($(this));
        });

        var modal = parent.find(".modal-img-large[colour='" + $(this).attr('colour') + "']");
        modal
            .on('shown.bs.modal', function () {
                parent.find('.modal-backdrop').hide();

                var $imageCarousels = modal.find('.carousel-thumbs-lg, .carousel-product-images-lg');
                slickSetPositionAndGoTo($imageCarousels, true, true);

                imageService.triggerLazy();

                // prevent scroll try in case of situations when modal dialog is not scrollable
                modal
                    .find('.modal-body')
                    .on('touchstart', function (e) {
                        $(this).data('touchY', Util.getTouchPoint(e).y);
                    })
                    .on('touchmove', function (e) {
                        if (this.clientHeight == this.scrollHeight) {
                            e.stopPropagation();
                            e.preventDefault();
                        } else {
                            var prevTouchY = $(this).data('touchY');
                            var currentTouchY = Util.getTouchPoint(e).y;
                            if (prevTouchY != null && currentTouchY != null) {
                                if (
                                    (this.scrollTop <= 0 && prevTouchY < currentTouchY)
                                    || (this.scrollTop + this.clientHeight >= this.scrollHeight
                                        && prevTouchY > currentTouchY)
                                ) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                            }
                        }
                        $(this).data('touchY', Util.getTouchPoint(e).y);
                    });
                Util.fixScroll(true);
            })
            .on('hide.bs.modal', function () {
                Util.fixScroll(false);
                modal
                    .find('.modal-body')
                    .off('touchstart')
                    .off('touchmove');
            });
    });
}

function constructShopTheLookCarousel(carousel) {
    return $(carousel).slick({
        infinite: true,
        dots: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    });
}

function initShopTheLookCarousels() {
    var $sizeSelectpickers = $('.js-section-look .individual-dropdown.js-stl-selectpicker');

    $('.js-section-look.container:not(".hide") .carousel-look').each(function () {
        if ($(this).hasClass('slick-initialized')) {
            $(this).slick('setPosition');
        } else {
            var shopTheLookSlcik = constructShopTheLookCarousel(this);
            // Make the first slide to be selected
            pdpStatus.carouselLookGalleryIndex = 0;

            shopTheLookSlcik
                .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    pdpStatus.carouselLookGalleryIndex = nextSlide;
                    $('.shop-the-look-select.open').removeClass('open');
                })
                .on('after_detach_cloned', function () {
                    // These select pickers will be reinitialized during 'after_create_cloned' event
                    $sizeSelectpickers.selectpicker('destroy');
                })
                .on('after_create_cloned', function () {
                    $sizeSelectpickers.selectpicker();
                    $sizeSelectpickers.selectpicker('render');

                    // Make selection of cloned selectpickers based on original selectpickers
                    $(".js-section-look .js-stl-product:not('.slick-cloned') .individual-dropdown.js-stl-selectpicker")
                        .each(function (i, select) {
                            var selectedValue = $(select).val();
                            var $parent = $(select).parents('.js-stl-swatchinfo-wrap');
                            var colourCode = $parent.data('shopthelook-swatchinfo-colourcode');
                            var groupCode = $parent.data('shopthelook-swatchinfo-groupcode');
                            var parentColourCode = $parent.data('shopthelook-swatchinfo-parentcolourcode');
                            var productCode = $parent.data('shopthelook-swatchinfo-productcode');

                            $(".js-section-look .js-stl-product.slick-cloned[productcode='"
                                    + productCode
                                    + "']"
                                    + " .js-stl-swatchinfo-wrap[data-shopthelook-swatchinfo-productcode='"
                                    + productCode
                                    + "']"
                                    + "[data-shopthelook-swatchinfo-colourcode='"
                                    + colourCode
                                    + "']"
                                    + "[data-shopthelook-swatchinfo-groupcode='"
                                    + groupCode
                                    + "']"
                                    + "[data-shopthelook-swatchinfo-parentcolourcode='"
                                    + parentColourCode
                                    + "']"
                                    + ' select.js-stl-selectpicker').each(function (i, selectCloned) {
                                $(selectCloned).selectpicker('val', selectedValue);
                                $(selectCloned).selectpicker('render');
                            });
                        });
                });
        }
    });
}

function updateShopTheLookCarousels() {
    $('.js-section-look.container:not(".hide") .carousel-look').each(function () {
        if ($(this).hasClass('slick-initialized')) {
            $(this).slick('unslick');
        }

        var shopTheLookSlcik = constructShopTheLookCarousel(this);
        if (shopTheLookSlcik.hasClass('slick-initialized')) {
            shopTheLookSlcik.slick('setPosition');
        }
        shopTheLookSlcik.slick('slickGoTo', pdpStatus.carouselLookGalleryIndex, true);
    });
}

function toggleShowMoreSwatches(event) {
    if (event.data.showMore) {
        $('#swatch-btn-showmore').addClass('hide');
        $('.js-swatches-row2').removeClass('hide');
    } else {
        $('#swatch-btn-showmore').removeClass('hide');
        $('.js-swatches-row2').addClass('hide');
    }
}

function checkWatchTimeout() {
    if (ACC.config.pdp.reviews.requestTime <= 0) {
        $('.results-failed').fadeOut();
        clearInterval(ACC.config.pdp.reviews.timer);
    } else if (ACC.config.pdp.reviews.requestTime + ACC.config.plp.request.timeToError < new Date().getTime()) {
        $('.results-failed').fadeIn();
        $('#reviews-loader').removeClass('loading');
    } else {
        $('.results-failed').fadeOut();
    }
}

function setWatchTimeout(date) {
    ACC.config.pdp.reviews.requestTime = date;
    ACC.config.pdp.reviews.timer = setInterval(function () {
        checkWatchTimeout();
    }, 200);
}

function resetWatchTimeout() {
    ACC.config.pdp.reviews.requestTime = 0;
}

function initShopTheLookSelectpickers() {
    $('.js-section-look.container:not(".hide") .js-stl-selectpicker').selectpicker();
}

function showPriceBySwatchColour(swatchColour, parentSelection) {
    if (parentSelection == null) {
        parentSelection = $('body');
    }
    parentSelection.find('div.product-price-for-selected-variant').addClass('hide');
    parentSelection
        .find('div[class^="colour-specific-"]')
        .parent()
        .addClass('hide');
    parentSelection
        .find('div[class="colour-specific-' + swatchColour + '"]')
        .parent()
        .removeClass('hide');
}

/**
 * Activate swatch in STC section and set clicked swatch as active.
 * @param {string[]} groupCodes
 * @param {string} colourCode
 * @param {string} firstColourCode
 */
function sortGroupSwatches(groupCodes, colourCode, firstColourCode) {
    var activeClass = 'active';

    groupCodes.forEach(function (groupCode) {
        var $group = $("[data-swatchcode-info='" + colourCode + "'][data-collectiongroupcode-info='" + groupCode + "']");

        // Toggle active state on swatches
        $group.find('.swatch.active').removeClass(activeClass);
        $group.find('.swatch[data-shopthecollection-swatch-colourcode="' + colourCode + '"]').addClass(activeClass);

        // Move swatch to first place
        var $movingSwatch = $group.find('.swatch[data-shopthecollection-swatch-colourcode="' + firstColourCode + '"]');
        $movingSwatch.prependTo($movingSwatch.parent());

        // Toggle additional class on last swatches to fix show less/show more switching
        var $additional = $group.find('.SwatchContainer-additionalSwatch');
        var additionalCount = $additional.length;
        if (additionalCount > 0) {
            $additional.removeClass('SwatchContainer-additionalSwatch');
            $group
                .find('.swatch')
                .toArray()
                .reverse()
                .forEach(function (el, index) {
                    if (index < additionalCount) {
                        $(el).addClass('SwatchContainer-additionalSwatch');
                    }
                });
        }
    });
}

function toggleWishlistIcon(selectTileDiv, productData) {
    if (window.ACC.config.toggles.wishList.CTA.pdpEnabled) {
        var currentProductCode = selectTileDiv.find(productData).val();
        var colourCode = null;
        var collectionGroupCode = null;
        var isProductExistInWishlist = null;

        if (!currentProductCode) {
            currentProductCode = selectTileDiv.data('shopthelook-swatchinfo-productcode');
            colourCode = selectTileDiv.data('shopthelook-swatchinfo-colourcode');
            collectionGroupCode = selectTileDiv.data('shopthelook-swatchinfo-groupcode') || null;
        }

        isProductExistInWishlist = window.addToBagCommons.isProductExistInWishlist(currentProductCode, colourCode, collectionGroupCode);

        var filledIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--filled.svg';
        var emptyIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--empty.svg';
        var selectedTileWishlist = selectTileDiv.find('.wishlist-icon');

        if (isProductExistInWishlist) {
            selectedTileWishlist.removeClass('heart-empty').addClass('heart-filled');
            selectedTileWishlist.attr('src', filledIconPath);
        } else if (selectedTileWishlist.hasClass('heart-filled')) {
            selectedTileWishlist.removeClass('heart-filled').addClass('heart-empty');
            selectedTileWishlist.attr('src', emptyIconPath);
        }
    }
}

function initPdpStatus() {
    pdpStatus = {
        selectedSwatchColour: null,
        swatchPreset: null,
        swatchGalleryIndex: {},
        preventGalleryChange: false
    };
}

/**
 * When TileGroupCollectionGroup Template mimicries TileGroupCollection template (no groupCode provided)
 */
function isTileGroupCollectionGroupTemplateCollectionLevel() {
    var result = false;
    if (isTileGroupCollectionGroupTemplate()) {
        var regex = new RegExp('/p/.*?/g/(.*)');
        var results = regex.exec(window.location.href);
        if (!results || !results[1]) {
            result = true;
        }
    }
    return result;
}

function getAddToBagInstance() {
    var result = null;

    if (isCollectionProduct()) {
        if (isTileGroupCollectionTemplate() || isTileGroupCollectionGroupTemplateCollectionLevel()) {
            result = AddToBagTileGroupCollectionHelper.getInstance();
        } else if (isTileGroupCollectionFragranceTemplate()) {
            result = AddToBagTileGroupCollectionFragranceHelper.getInstance();
        } else {
            result = AddToBagCollectionHelper.getInstance();
        }
    } else {
        result = AddToBagBaseHelper.getInstance();
    }

    return result;
}

function updateJsonLdSeoObj(colourName) {
    var $this = document.getElementById('seoSemantic');
    var jsonObj = JSON.parse($this.text);
    jsonObj.color = colourName;
    $this.text = JSON.stringify(jsonObj).replace(/-/g, ' ');
}

function mainImageSelectOnChange(event) {
    var activeColour = event.currentTarget.dataset.swatchColour;
    var activeThumb = document.querySelector('.carousel-thumbs .thumb-clicked [colour="' + activeColour + '" ]');
    var slickIndex = activeThumb === null ? 0 : Number(activeThumb.getAttribute('index'));
    var productImages = document.querySelector('.carousel-product-images[colour="' + activeColour + '" ]');
    var carouselThumbs = document.querySelector('.carousel-thumbs[colour="' + activeColour + '" ]');
    var slideToShow = Number($(carouselThumbs).slick('slickGetOption', 'slidesToShow')) - 1;
    var goToSlide = slickIndex < slideToShow ? slickIndex : (slickIndex - slideToShow);

    if (activeThumb === null) {
        carouselThumbs.querySelector('.slick-slide').classList.add('thumb-clicked');
    }

    productImages.slick.slickGoTo(Number(slickIndex), true);
    carouselThumbs.slick.slickGoTo(goToSlide, true);
}

function mobilePinchToZoom(isDelay) {
    function toggleSlick(hold) {
        $('.carousel-product-images').each(function () {
            $(this).slick('slickSetOption', 'accessibility', hold);
            $(this).slick('slickSetOption', 'draggable', hold);
            $(this).slick('slickSetOption', 'swipe', hold);
            $(this).slick('slickSetOption', 'touchMove', hold);
        });
    }

    $(function () {
        var swatchContainer = $('.SwatchContainer');

        function updateEventLog(e) {
            if (e.type == PinchZoomer.ZOOM.split('.')[0]) {
                if (e.zoom == 1) {
                    toggleSlick(true);
                } else {
                    toggleSlick(false);
                }
            }
        }

        function getActiveColour() {
            var activeColour;
            if (swatchContainer.length > 0) {
                activeColour = swatchContainer.find('.active').attr('data-swatch-colour');
                if (activeColour.indexOf('/') > -1) {
                    activeColour = activeColour.split('/').join('\\/');
                }

                return activeColour;
            }

            return null;
        }

        function getActiveSlickId() {
            var activeSlickId;
            var activeColour = getActiveColour();

            if (activeColour !== null) {
                activeSlickId = $('[data-swatch-info=' + activeColour + '] .slick-active > .pinchzoomer__wrapper')[0].id;
            } else {
                activeSlickId = $('#productImageMobile').find('.slick-active .pinchzoomer__wrapper')[0].id;
            }

            return activeSlickId;
        }

        var pinchZoomer = PinchZoomer.get(getActiveSlickId());

        $(window).load(function () {
            $('.pinchimg__s').first().one('load', function () {
                $('.pinchzoomer__wrapper').height($('.pinchimg__s').height());

                // If notify me size has been preselected - scroll to size picker on image load
                var mobileScreenBreakpoint = 768;
                if (window.isSizePreselectingHandled && window.screen.width < mobileScreenBreakpoint) {
                    scrollToElement('.notify-me--enabled', -$('header').height() - 5);
                }
            });
        });

        $('.carousel').on('afterChange', function () {
            pinchZoomer = PinchZoomer.get(getActiveSlickId());
            pinchZoomer.on(PinchZoomer.ZOOM, updateEventLog);
            pinchZoomer.resetElem(true);
        });

        $('.carousel').on('beforeChange', function () {
            pinchZoomer = PinchZoomer.get(getActiveSlickId());
            pinchZoomer.on(PinchZoomer.ZOOM, updateEventLog);
            pinchZoomer.zoom(0);
            PinchZoomer.resetDoubleTabCounter(0);
        });

        if (pinchZoomer) {
            if (isDelay) {
                setTimeout(
                    function () {
                        pinchZoomer.on(PinchZoomer.ZOOM, updateEventLog);
                        pinchZoomer.resetElem(true);
                    }, 1000
                );
            } else if (isDelay === false && getActiveColour() !== null) {
                $('[data-swatch-info=' + getActiveColour() + '] .slick-active > .pinchzoomer__wrapper').each(function () {
                    var slickID = $(this).attr('id');
                    var pz = PinchZoomer.get(slickID);
                    pz.resetElem(true);
                });
                setTimeout(
                    function () {
                        pinchZoomer.resetElem(true);
                    }, 1
                );
            }
        }
    });
}

function toggleBackorder() {
    $('.js-backorder').addClass('hide');
}

function onClickSwatch(event) {
    var scroll = $(window).scrollTop();
    var selectedSwatchColourCode = $(this).data('swatch-colourcode');

    if (!_.isEqual(pdpStatus.selectedSwatchColourCode, selectedSwatchColourCode)) {
        window.isSizePreselectingHandled = false;

        var updatedSearchPath = new URLSearchParams({ swatch: $(this).data('swatch-colourname') }).toString();

        window.historyproxy.replaceState(
            null,
            document.title,
            window.location.origin + window.location.pathname + '?' + updatedSearchPath
        );
    }

    pdpStatus.selectedSwatchColour = $(this).data('swatch-colour');
    pdpStatus.selectedSwatchColourCode = selectedSwatchColourCode;

    var $productSwatch = $('li[data-swatch-colour]');
    var $productSwatchActive = $productSwatch.filter("[data-swatch-colour='" + pdpStatus.selectedSwatchColour + "']");
    var $productData = $('[data-swatch-info], [data-swatchcode-info]');
    var $productDataActive = $productData.filter("[data-swatch-info='"
            + pdpStatus.selectedSwatchColour
            + "'], [data-swatchcode-info='"
            + pdpStatus.selectedSwatchColourCode
            + "']");

    $productSwatch.removeClass('active');
    $productSwatchActive.addClass('active');
    $productData.addClass('hide');
    $productDataActive.removeClass('hide');

    showPriceBySwatchColour(pdpStatus.selectedSwatchColour);
    initShopTheLookCarousels();
    initShopTheLookSelectpickers();
    showSelectedRecommendations(pdpStatus.selectedSwatchColour);
    updateJsonLdSeoObj(pdpStatus.selectedSwatchColour);
    var groupCodes = $(".js-stc-product[data-collectiongroupcode-info][data-swatchcode-info='"
            + pdpStatus.selectedSwatchColourCode
            + "']")
        .toArray()
        .map(function (element) {
            return $(element).data('collectiongroupcode-info');
        });
    sortGroupSwatches(groupCodes, pdpStatus.selectedSwatchColourCode, pdpStatus.selectedSwatchColourCode);

    var $imageCarousels = $productDataActive.filter('.js-carousel-thumbs, .js-carousel-product-images');
    slickSetPositionAndGoTo($imageCarousels, true, true);

    imageService.initPresets(
        null,
        function () {
            imageService.initLazy();
            imageService.forceLazy('.js-section-look:visible .js-stl-swatchinfo-wrap:visible .js-product-image img');
            imageService.triggerLazy();
        },
        true
    );

    $(this)
        .parents('#modal-quickview')
        .find('.pdp-link')
        .attr('href', $(this).attr('variant-url'));

    getAddToBagInstance().showSelectSizeAndQuantityBySwatchColour(pdpStatus.selectedSwatchColour);

    $(window).scrollTop(scroll); // restore scroll position after show/hide elements
    resetImgViewerWhileVideoIsActive();
    hideVideoPlayer();
    mainImageSelectOnChange(event);
    mobilePinchToZoom(false);

    toggleBackorder();
}

function refreshMiniCart() {
    if (!window.ACC.config.minicart) {
        window.dispatchEvent(new CustomEvent('UPDATE_HEADER_BAG'));
        return;
    }
    // TODO: decommissioning_header remove get request, remove if wrapper from above
    $.get(ACC.config.minicart.refreshMiniCartUrl + Math.floor(Math.random() * 101) * new Date().getTime(), function (result) {
        var $itemsText = $('[data-minicart-items]');
        setTimeout(function () {
            $itemsText.text(result.items);

            var addToBagIcon = $itemsText.parents('.icon')[0];
            if (result.items > 0) {
                addToBagIcon.className = 'icon icon--bag-full';
            } else {
                addToBagIcon.className = 'icon icon--bag';
            }

            $('[data-minicart-price]').text(result.price);
        }, addToBagSnackbarShowingTime);
    });
}

function showAddToBagCartPopup(responseData) {
    var responseHtml = responseData.cartPopupHtml;
    refreshMiniCart();

    // event for header to accept data
    var event = new CustomEvent('TRIGGER_ADDED_TO_BAG_POPUP', {
        detail: responseData,
    });
    window.dispatchEvent(event);

    // TODO: decommissioning_header remove old logic
    if (!Util.isMobile()) {
        var cartPopup = $('#cart_popup');
        cartPopup.hide();
        cartPopup.html(responseHtml);

        // Set correct preset for thumb image
        imageService.initPresets('#cart_popup');
        $('#add_to_cart_close').click(function (e) {
            e.preventDefault();
            cartPopup.hide();
        });

        cartPopup.fadeIn();
        if (typeof timeoutId !== 'undefined') {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(function () {
            cartPopup.fadeOut();
        }, 5000);
    }
}

function addToBagSuccess(result) {
    if (result != null && result.responseJSON != null && Util.isNotEmpty(result.responseJSON.cartPopupHtml)) {
        var messageContainer = $('#errorMessageContainer');
        if (result.responseJSON.errorExists === 'true' && result.responseJSON.errorMessage !== 'noStock') {
            if (result.responseJSON.errorMessage != null && result.responseJSON.errorMessage !== '') {
                $('#infoMessage').html(result.responseJSON.errorMessage);
            }
            messageContainer.show();
        } else {
            messageContainer.hide();
            showAddToBagCartPopup(result.responseJSON);
            // TODO: decommissioning_header remove old overlay
            window.addToBagSnackbar.show(result.responseJSON.cartPopupHtml);
            imageService.initPresets('.add-to-bag-snackbar');
        }
    }
}

function fireAddToBagResponse(data) {
    window.dispatchEvent(new CustomEvent('add-to-bag-response', {
        detail: data
    }));
}

function initAddToBagCollectionOrBaseProduct() {
    var $form = $('.add_to_cart_form');
    var button = window.addToBagButton.getButton($form);
    var states = window.addToBagButton.stateClasses;
    $form.ajaxForm({
        beforeSend: function () {
            button.setState(states.adding);
        },
        success: function (data) {
            if (data.status === 'SUCCESS' && data.errorExists !== 'true') {
                button.setState(states.added);

                fireAddToBagResponse(data);
            } else {
                button.setState(states.error);
            }
        },
        error: function () {
            button.setState(states.error);
        },
        complete: addToBagSuccess
    });
}

function onClickShopTheLookSwatch(event) {
    var scroll = $(window).scrollTop();

    var parentcolourcode = $(this).data('shopthelook-swatch-parentcolourcode');
    var productcode = $(this).data('shopthelook-swatch-productcode');
    var colourcode = $(this).data('shopthelook-swatch-colourcode');
    var groupcode = $(this).data('shopthelook-swatch-groupcode');

    var $shopTheLookProductSwatch = $("[data-shopthelook-swatch-parentcolourcode='"
            + parentcolourcode
            + "'][data-shopthelook-swatch-productcode='"
            + productcode
            + "'][data-shopthelook-swatch-groupcode='"
            + groupcode
            + "']");
    var $shopTheLookProductSwatchActive = $shopTheLookProductSwatch.filter("[data-shopthelook-swatch-colourcode='" + colourcode + "']");
    var $shopTheLookProductData = $("[data-shopthelook-swatchinfo-parentcolourcode='"
            + parentcolourcode
            + "'][data-shopthelook-swatchinfo-productcode='"
            + productcode
            + "'][data-shopthelook-swatchinfo-groupcode='"
            + groupcode
            + "']");
    var $shopTheLookProductDataActive = $shopTheLookProductData.filter("[data-shopthelook-swatchinfo-colourcode='" + colourcode + "']");

    $shopTheLookProductSwatch.removeClass('active');
    $shopTheLookProductSwatchActive.addClass('active');
    $shopTheLookProductData.addClass('hide');
    $shopTheLookProductDataActive.removeClass('hide');
    $(this)
        .parents('.product-swatches')
        .find('.pdp-link')
        .attr('href', $(this).attr('variant-url'));
    $(window).scrollTop(scroll); // restore scroll position after show/hide elements

    imageService.initPresets(
        null,
        function () {
            imageService.initLazy();
            imageService.forceLazy('.js-section-look:visible .js-stl-swatchinfo-wrap:visible .js-product-image img');
            imageService.triggerLazy();
        },
        true
    );
    var productDescriptionDiv = $(this).closest('div.product-description');
    var selectedSwatchWrap = productDescriptionDiv.find('.js-stl-swatchinfo-wrap:not(.hide)').children('div.row').parent();

    if (!selectedSwatchWrap.length) {
        selectedSwatchWrap = productDescriptionDiv.find('.js-stl-swatchinfo-wrap:not(.hide)');
    }

    toggleWishlistIcon(selectedSwatchWrap, '.codeholder-dropdown:visible option:selected');
}

function onClickShopTheCollectionSwatch(event) {
    var scroll = $(window).scrollTop();

    var firstSwatchCode = $(this)
        .parents('.js-stc-product')
        .find('.swatch:first')
        .data('shopthecollection-swatch-colourcode');

    var colourCode = $(this).data('shopthecollection-swatch-colourcode');
    var groupCode = $(this).data('shopthecollection-collectiongroupcode');
    var colour = $(this).data('shopthecollection-swatch-colour');

    var $shopTheCollectionGroupData = $("[data-collectiongroupcode-info='" + groupCode + "']");
    var $shopTheCollectionGroupDataActive = $shopTheCollectionGroupData.filter("[data-swatchcode-info='" + colourCode + "']");
    $shopTheCollectionGroupData.addClass('hide');
    $shopTheCollectionGroupDataActive.removeClass('hide');

    sortGroupSwatches([groupCode], colourCode, firstSwatchCode);
    showPriceBySwatchColour(colour, $shopTheCollectionGroupDataActive);

    imageService.triggerLazy();

    getAddToBagInstance().showSelectSizeAndQuantityBySwatchColour(colour, $shopTheCollectionGroupDataActive);

    $(window).scrollTop(scroll); // restore scroll position after show/hide elements
    var productDescriptionDiv = $(this).closest('div.product-description');
    var selectedSwatchWrap = productDescriptionDiv.find('.js-stl-swatchinfo-wrap:not(.hide)').children('div.row').parent();

    if (!selectedSwatchWrap.length) {
        selectedSwatchWrap = productDescriptionDiv.find('.js-stl-swatchinfo-wrap:not(.hide)');
    }

    toggleWishlistIcon(selectedSwatchWrap, '.codeholder-dropdown:visible option:selected');
}

function showHideLoadMoreSection() {
    var loadStart = ACC.config.pdp.reviews.pagesize.regular * ACC.config.pdp.reviews.page + ACC.config.pdp.reviews.pagesize.first;
    if (loadStart < ACC.config.pdp.reviews.totalCount) {
        $('.load-more').show();
    } else {
        $('.load-more').hide();
    }
}

function loadMoreReviews() {
    var page = ACC.config.pdp.reviews.page;
    var totalCount = ACC.config.pdp.reviews.totalCount;

    var loadStart = ACC.config.pdp.reviews.pagesize.regular * page + ACC.config.pdp.reviews.pagesize.first;

    if (loadStart < totalCount) {
        var urlBase = window.location.href.split(/[?#]/)[0];
        var url = urlBase + '/getReviewPage/' + (page + 1);

        history.replaceState(
            null,
            document.title,
            Util.replaceQueryParam(window.location.href, 'reviewsPage', page + 1)
        );

        $('#reviews-loader').addClass('loading');
        $('.load-more').hide();
        setWatchTimeout(new Date().getTime());

        $.ajax({
            url: url,
            type: 'GET',
            data: {},
            tryCount: 0,
            success: function (dataResult) {
                $('#reviewTemplate')
                    .tmpl(dataResult)
                    .appendTo($('#reviews'));
                ACC.config.pdp.reviews.page++;
                $('#reviews-loader').removeClass('loading');
                showHideLoadMoreSection();
                resetWatchTimeout();
            },
            error: function (xhr, textStatus, errorThrown) {
                this.tryCount++;
                setTimeout(
                    function () {
                        if (this.tryCount <= ACC.config.plp.request.maxRetries) {
                            $.ajax(this);
                        }
                    }.bind(this),
                    ACC.config.plp.request.retryPeriod
                );
            }
        });
    }
}

function initShopTheLookAddToBag() {
    // Required due to slick implementation. It creates cloned tiles, so all tiles (original and cloned) should be updated
    function getAllWrappers(currentElement) {
        var parent = null;
        if ($(currentElement).hasClass('js-stl-swatchinfo-wrap')) {
            parent = $(currentElement);
        } else {
            parent = $(currentElement).parents('.js-stl-swatchinfo-wrap');
        }
        var parentcolourcode = parent.data('shopthelook-swatchinfo-parentcolourcode');
        var productcode = parent.data('shopthelook-swatchinfo-productcode');
        var colourcode = parent.data('shopthelook-swatchinfo-colourcode');
        var groupcode = parent.data('shopthelook-swatchinfo-groupcode');

        var selector = "[data-shopthelook-swatchinfo-parentcolourcode='"
            + parentcolourcode
            + "']"
            + "[data-shopthelook-swatchinfo-productcode='"
            + productcode
            + "']"
            + "[data-shopthelook-swatchinfo-colourcode='"
            + colourcode
            + "']";

        if (Util.isNotEmpty(groupcode)) {
            selector += "[data-shopthelook-swatchinfo-groupcode='" + groupcode + "']";
        }
        return $(selector);
    }

    // Set Add To Bag button state on all slides: cloned and original
    function setAddToBagButtonState(parent, state) {
        var colourCode = $(parent).data('shopthelook-swatchinfo-colourcode');
        var groupCode = $(parent).data('shopthelook-swatchinfo-groupcode');
        var parentColourCode = $(parent).data('shopthelook-swatchinfo-parentcolourcode');
        var productCode = $(parent).data('shopthelook-swatchinfo-productcode');

        $(".js-section-look .js-stl-product[productcode='"
                + productCode
                + "']"
                + " .js-stl-swatchinfo-wrap[data-shopthelook-swatchinfo-productcode='"
                + productCode
                + "']"
                + "[data-shopthelook-swatchinfo-colourcode='"
                + colourCode
                + "']"
                + "[data-shopthelook-swatchinfo-groupcode='"
                + groupCode
                + "']"
                + "[data-shopthelook-swatchinfo-parentcolourcode='"
                + parentColourCode
                + "']").each(function (i, el) {
            window.addToBagButton.getButton(el).setState(state);
        });
    }

    function updatePrice(priceDiv, price) {
        if (Util.isNotEmpty(price)) {
            var currentHtml = $('<div>')
                .append(priceDiv.clone())
                .html();
            var currentDataPrice = priceDiv.attr('data-price');

            var priceDivParent = priceDiv.parent();
            priceDiv.replaceWith(price);
            priceDiv = priceDivParent.children('.product-price');

            if (Util.isEmpty(currentDataPrice)) {
                priceDiv.attr('data-price', currentHtml);
            } else {
                priceDiv.attr('data-price', currentDataPrice);
            }
        } else if (priceDiv.attr('data-price') != null) {
            priceDiv.replaceWith(priceDiv.attr('data-price'));
        }
    }

    function updateShopTheLookTile(parent, codeholderSelector, masterSelector) {
        var productCode = parent.find(codeholderSelector).val();
        var button = window.addToBagButton.getButton(parent);
        var states = window.addToBagButton.stateClasses;

        if (Util.isNotEmpty(productCode)) {
            button.setState(states.add);
            updatePrice(
                parent.find('.product-price:visible'),
                parent.find(codeholderSelector).attr('data-price-content')
            );
        } else {
            button.toggleDisabled(true);
            updatePrice(parent.find('.product-price:visible'), '');
        }
    }

    function addToBag(parent, productCode) {
        var states = window.addToBagButton.stateClasses;

        setAddToBagButtonState(parent, states.adding);

        // updateShopTheLookAddToBagButton(getAllWrappers(parent).find("button[type='submit']"), "btn-adding");
        var failedToAdd = function () {
            // updateShopTheLookAddToBagButton(getAllWrappers(parent).find("button[type='submit']"), "btn-unable");
            setAddToBagButtonState(parent, states.error);
        };
        $.post(ACC.config.baseUrl + 'cart/add', {
            productCodePost: productCode,
            productQuantity: 1,
            CSRFToken: ACC.config.CSRFToken,
            source: 'ShopTheLook',
            responsive: true
        })
            .success(function (data) {
                if (data.status === 'SUCCESS') {
                    setAddToBagButtonState(parent, states.added);

                    fireAddToBagResponse(data);
                } else {
                    failedToAdd();
                }
            })
            .fail(failedToAdd)
            .always(function (data) {
                if (data != null && Util.isNotEmpty(data.cartPopupHtml)) {
                    // TODO: decommissioning_header remove old overlay
                    window.addToBagSnackbar.show(data.cartPopupHtml);
                    imageService.initPresets('.add-to-bag-snackbar');
                    showAddToBagCartPopup(data);
                }
            });
    }

    function notifyMeSubmit(email, code) {
        var notifyMe = $('#notify-me');

        function success(response) {
            notifyMe.addClass('submit--success');
            $('.notify-me__success__close').on('click', function () {
                notifyMe.removeClass('submit--success prompt--show');
            });
        }
        function failure() {
            notifyMe.addClass('submit--failure');
        }

        window.addToBagCommons.vanillaFetch(
            '/api/product/notifyme',
            {
                email: email,
                code: code,
            },
            success,
            failure
        );
    }

    $('.master-dropdown').on('change', function () {
        $(this)
            .parents('.js-stl-swatchinfo-wrap')
            .find('.slave-dropdown')
            .each(function () {
                $(this).selectpicker('hide');
            });
        $(this)
            .parents('.js-stl-swatchinfo-wrap')
            .find(".slave-dropdown[data-master-dropdown-value='"
                    + Util.defaultIfEmpty(
                        $(this)
                            .find('option:selected')
                            .val(),
                        0
                    )
                    + "']")
            .each(function () {
                $(this).selectpicker('show');
            });
    });
    $('.slave-dropdown, .master-dropdown, .codeholder-dropdown').on('change', function () {
        var selected = $(this).find('option:selected').val();

        getAllWrappers(this).find('.' + $(this)
            .attr('class')
            .split(' ')
            .join('.'))
            .each(function (i, e) {
                if (Util.isNotEmpty($(e).find("option[value='" + selected + "']"))) {
                    var currentValue = $(e)
                        .find('option:selected')
                        .val();
                    if (currentValue != selected) {
                        $(e).val(selected);
                        $(e).trigger('change');
                    }
                }
            });

        updateShopTheLookTile(
            $(this).parents('.js-stl-swatchinfo-wrap'),
            '.codeholder-dropdown:visible option:selected',
            '.master-dropdown:visible option:selected'
        );
        toggleWishlistIcon($(this).parents('.js-stl-swatchinfo-wrap'),
            '.codeholder-dropdown:visible option:selected');
    });
    $('.master-dropdown').trigger('change');

    $('.codeholder-dropdown').on('loaded.bs.select', function () {
        updateShopTheLookTile(
            $(this).parents('.js-stl-swatchinfo-wrap'),
            '.codeholder-dropdown option:selected',
            '.master-dropdown option:selected'
        );
        toggleWishlistIcon($(this).parents('.js-stl-swatchinfo-wrap'),
            '.codeholder-dropdown option:selected');
    });

    $('.js-stl-swatchinfo-wrap button[type="submit"]').on('click', function () {
        var states = window.addToBagButton.stateClasses;

        if ($(this).hasClass(states.add) || $(this).hasClass(states.added) || $(this).hasClass(states.error)) {
            addToBag(
                $(this).parents('.js-stl-swatchinfo-wrap'),
                $(this)
                    .parents('.js-stl-swatchinfo-wrap')
                    .find('.codeholder-dropdown:visible option:selected')
                    .val()
            );
        }
    });

    $('.js-section-look').data('addToBagReady', true);

    $('.notify-me__form__submit').on('click', function (e) {
        e.preventDefault();
        var notifyMeForm = $('.notify-me__form');
        var email = notifyMeForm.find($('.notify-me__form__email')).val();
        // eslint-disable-next-line
        var filter = new RegExp(ACC.config.regex.emailValidation);
        if (filter.test(email)) {
            if (notifyMeForm.hasClass('invalid-email')) {
                notifyMeForm.removeClass('invalid-email');
            }
            notifyMeSubmit(email, $('#productCodePost').val());
        } else {
            notifyMeForm.addClass('invalid-email');
        }
    });
}


function initZoom() {
    function getZoomOn() {
        if (Util.isTouch()) {
            if (Util.isMobile()) {
                return '';
            }
            return 'click';
        }
        return 'mouseover';
    }

    function slickUntouch() {
        $('.product-image__wrapper > .carousel-product-images').each(function () {
            $(this)
                .find('.img-main')
                .trigger('zoomShow');
            $(this).slick('slickSetOption', 'swipe', false, false);
            $(this).slick('slickSetOption', 'draggable', false, false);
        });
    }

    function slickTouch() {
        $('.product-image__wrapper > .carousel-product-images').each(function () {
            $(this)
                .find('.img-main')
                .trigger('zoomHide');
            $(this).slick('slickSetOption', 'swipe', true, true);
            $(this).slick('slickSetOption', 'draggable', true, true);
        });
    }

    $(document).ready(function () {
        var mediaType = Util.getMediaType();
        var preset = ACC.config.pdp.preset.zoomImage[mediaType];

        $('#product-image').find('.img-main').each(function (index, image) {
            $(image)
                .wrap('<span style="display:block"></span>')
                .parent()
                .zoom({
                    url: Util.addPreset($(image).attr('src-np'), '', preset),
                    on: getZoomOn(),
                    touch: true,
                    magnify: 1,
                    callback: function () {
                        $(this)
                            .closest('span')
                            .on('mousedown touchstart', function (e) {
                                pdpStatus.preventGalleryChange = true;
                                setTimeout(function () {
                                    pdpStatus.preventGalleryChange = false;
                                }, 100);
                            });
                    },
                    onZoomIn: slickUntouch,
                    onZoomOut: slickTouch
                });
            $(image)
                .parent()
                .prepend('<div class="curtain"></div>');
        });

        function updateZoom(mediaType) {
            var preset = ACC.config.pdp.preset.zoomImage[mediaType];

            $('div.productimg__original > img.img-main').each(function (index, image) {
                var $parent = $(image).parent();
                var zoomImage = Util.addPreset($(image).attr('src-np'), '', preset);
                window.imageLoader.loadInBackground(zoomImage).then(function reloadZoomPlugin(src) {
                    $parent.trigger('zoom.destroy');
                    $parent.zoom({
                        url: src,
                        on: getZoomOn(),
                        touch: true,
                        magnify: 1,
                        onZoomIn: slickUntouch,
                        onZoomOut: slickTouch
                    });
                });
            });
        }

        window.responsiveUtils.onMediaTypeChanged(updateZoom);
    });
}

function initOther() {
    $('a#product-review-link').click(function () {
        $('html,body').animate(
            {
                scrollTop: $('#section-reviews').offset().top
            },
            'slow'
        );
    });

    // Preload size guide content
    $('.js-size-modal-link').each(function () {
        var $link = $(this);
        var $targetModal = $($link.attr('href'));
        var $targetModalBody = $targetModal.find('.modal-body');

        $.get($link.attr('data-link'), function (data) {
            $targetModalBody.data('sizeGuideHtmlContent', data);
        });

        function clearSizeGuideContent() {
            $targetModalBody.empty();
        }

        function populateSizeGuideContent() {
            $targetModalBody.append($targetModalBody.data('sizeGuideHtmlContent'));
        }

        $targetModal.on('show.bs.modal', populateSizeGuideContent).on('hidden.bs.modal', clearSizeGuideContent);

        function switchScrollLock(mediaType) {
            function tablet() {
                $targetModal.find('.modal-dialog').scrollLock('enable');
                $targetModal.scrollLock('disable');
            }

            function mobile() {
                $targetModal.find('.modal-dialog').scrollLock('disable');
                $targetModal.scrollLock('enable');
            }

            if (mediaType === 'mobile') {
                mobile();
            } else {
                tablet();
            }
        }
        window.responsiveUtils.onMediaTypeChanged(switchScrollLock);
        switchScrollLock(window.responsiveUtils.getMediaType());

        function disableBackgroundScroll($el) {
            $el.on('touchmove', function cancelDirectTouchScroll(event) {
                if (event.target === this) {
                    event.preventDefault();
                }
            });
        }

        disableBackgroundScroll($targetModal);
    });

    // Read more button script
    $('.js-read-more').click(function (e) {
        e.preventDefault();
        var readMoreText = $(this)
            .parent()
            .siblings('.js-description-tab-content');
        if (readMoreText.hasClass('toggled')) {
            $(this).removeClass('read-less');
            readMoreText.removeClass('toggled');
        } else {
            $(this).addClass('read-less');
            readMoreText.addClass('toggled');
        }
    });

    getAddToBagInstance().initDropdowns(currentSwatch);
}

function initZoomManual() {
    var $zoomHint = $('.m-product-image-expand');

    function hideHint() {
        $zoomHint.fadeOut();
    }

    if (!Util.isDesktop() && Util.isTouch()) {
        $zoomHint.removeClass('hide');
        $zoomHint.on('inview', function (e, visible) {
            if (visible) {
                $zoomHint.off('inview');
                setTimeout(hideHint, 3000);
            }
        });
    }
}

function toggleReadMoreButton() {
    var $parentContainer;
    var $contentContainer;

    if (Util.isMobile()) {
        $parentContainer = $('.js-description');
        $contentContainer = $('.js-description-tab-content');
    } else {
        $parentContainer = $('.js-description:eq(1)');
        $contentContainer = $('.js-description-tab-content:eq(1)');
    }

    var contentContainerHeight = $contentContainer.outerHeight();
    var contentContainerChildrenHeight = 0;
    $contentContainer.children().each(function () {
        contentContainerChildrenHeight += $(this).outerHeight();
    });
    if (contentContainerChildrenHeight <= contentContainerHeight) {
        $parentContainer.find('.js-panel-read-more').addClass('hide');
    }
}

function initPdpAnalytics() {
    var c = window.analytics.collectors;
    var cc = window.analytics.custom.collectors;
    var a = window.analytics.custom.actions;
    var v = window.analytics.custom.validators;

    window.analytics.click($('.product-review-count'), c.main(a.GO_TO_REVIEWS_CLICK));
    window.analytics.click(
        $('#product-details .swatch').not('.truncated'),
        c.merge(c.main(a.MAIN_SWATCH_CLICK), cc.mainSwatchCollector)
    );
    window.analytics.click($('.js-size-modal-link'), c.main(a.SIZE_GUIDE_CLICK));
    window.analytics.click($('.js-advisory-link'), c.main(a.BUYING_GUIDE_CLICK));

    // MAIN DROPDOWNS
    var $size = $('#product-details .select-size');
    var $qty = $('#product-details .select-qty');
    var $type = $('#product-details .select-variant');

    window.analytics.on(
        $size,
        'shown.bs.select',
        c.merge(c.main(a.MAIN_SIZE_DROPDOWN), cc.mainSwatchCollector, cc.staticExpandCollector)
    );
    window.analytics.on(
        $size,
        'hidden.bs.select',
        c.merge(c.main(a.MAIN_SIZE_DROPDOWN), cc.mainSwatchCollector, cc.staticCollapseCollector)
    );
    window.analytics.on(
        $qty,
        'shown.bs.select',
        c.merge(c.main(a.MAIN_QTY_DROPDOWN), cc.mainSwatchCollector, cc.staticExpandCollector)
    );
    window.analytics.on(
        $qty,
        'hidden.bs.select',
        c.merge(c.main(a.MAIN_QTY_DROPDOWN), cc.mainSwatchCollector, cc.staticCollapseCollector)
    );
    window.analytics.on(
        $type,
        'shown.bs.select',
        c.merge(c.main(a.MAIN_TYPE_DROPDOWN), cc.mainSwatchCollector, cc.staticExpandCollector)
    );
    window.analytics.on(
        $type,
        'hidden.bs.select',
        c.merge(c.main(a.MAIN_TYPE_DROPDOWN), cc.mainSwatchCollector, cc.staticCollapseCollector)
    );

    window.analytics.on(
        $size,
        'dropdownsChangeHandled',
        c.merge(
            c.main(a.MAIN_SIZE_DROPDOWN_CHANGE),
            cc.mainSwatchCollector,
            cc.mainSizeCollector,
            cc.mainTypeCollector,
            cc.mainQtyCollector,
            cc.mainVariantCollector
        )
    );
    window.analytics.on(
        $qty,
        'dropdownsChangeHandled',
        c.merge(
            c.main(a.MAIN_QTY_DROPDOWN_CHANGE),
            cc.mainSwatchCollector,
            cc.mainSizeCollector,
            cc.mainTypeCollector,
            cc.mainQtyCollector,
            cc.mainVariantCollector
        )
    );
    window.analytics.on(
        $type,
        'dropdownsChangeHandled',
        c.merge(
            c.main(a.MAIN_TYPE_DROPDOWN_CHANGE),
            cc.mainSwatchCollector,
            cc.mainSizeCollector,
            cc.mainTypeCollector,
            cc.mainQtyCollector,
            cc.mainVariantCollector
        )
    );

    window.analytics.click(
        $('#product-details .add-to-bag'),
        c.merge(
            c.main(a.MAIN_ADD_TO_BAG_CLICK),
            cc.mainSwatchCollector,
            cc.mainSizeCollector,
            cc.mainTypeCollector,
            cc.mainQtyCollector,
            cc.mainVariantCollector
        )
    );
    // MAIN DROPDOWNS end

    // SOCIAL MEDIA
    window.analytics.click($('.share'), c.merge(c.main(a.SOCIAL_MEDIA_CLICK), cc.socialMediaCollector));
    // SOCIAL MEDIA end

    // TABS
    window.analytics.click(
        $("[data-target='.js-description']"),
        c.merge(c.main(a.DETAILS_DESCRIPTION_CLICK), cc.staticExpandCollector)
    );
    window.analytics.click(
        $("[data-target='.js-desktop-size']"),
        c.merge(c.main(a.DETAILS_SIZE_CLICK), cc.staticExpandCollector)
    );
    window.analytics.click(
        $("[data-target='.js-desktop-delivery']"),
        c.merge(c.main(a.DETAILS_DELIVERY_CLICK), cc.staticExpandCollector)
    );
    window.analytics.on(
        $('#accordion-why'),
        'hidden.bs.collapse',
        c.merge(c.main(a.DETAILS_DESCRIPTION_CLICK), cc.accordionCollector)
    );
    window.analytics.on(
        $('#accordion-size'),
        'hidden.bs.collapse',
        c.merge(c.main(a.DETAILS_SIZE_CLICK), cc.accordionCollector)
    );
    window.analytics.on(
        $('#accordion-delivery'),
        'hidden.bs.collapse',
        c.merge(c.main(a.DETAILS_DELIVERY_CLICK), cc.accordionCollector)
    );
    window.analytics.on(
        $('#accordion-why'),
        'shown.bs.collapse',
        c.merge(c.main(a.DETAILS_DESCRIPTION_CLICK), cc.accordionCollector)
    );
    window.analytics.on(
        $('#accordion-size'),
        'shown.bs.collapse',
        c.merge(c.main(a.DETAILS_SIZE_CLICK), cc.accordionCollector)
    );
    window.analytics.on(
        $('#accordion-delivery'),
        'shown.bs.collapse',
        c.merge(c.main(a.DETAILS_DELIVERY_CLICK), cc.accordionCollector)
    );
    window.analytics.click($('.js-read-more'), c.merge(c.main(a.DETAILS_READ_MORELESS_CLICK), cc.readMoreCollector));
    // TABS end

    // IMAGE
    window.analytics.on(
        $('.img-main'),
        'zoomShow',
        c.merge(c.main(a.IMG_ZOOM), cc.staticZoomShowCollector, cc.mainSwatchCollector)
    );
    window.analytics.on(
        $('.img-main'),
        'zoomHide',
        c.merge(c.main(a.IMG_ZOOM), cc.staticZoomHideCollector, cc.mainSwatchCollector)
    );
    window.analytics.click($('.modal-link-large'), c.merge(c.main(a.IMG_VIEW_LARGE_IMG_CLICK), cc.mainSwatchCollector));
    window.analytics.on(
        $('.carousel-product-images'),
        'afterChange',
        c.merge(c.main(a.IMG_CHANGE), cc.galleryChangeCollector, cc.mainSwatchCollector),
        v.galleryChangeValidator
    );
    window.analytics.on(
        $('.carousel-product-images-lg'),
        'afterChange',
        c.merge(c.main(a.IMG_LARGE_CHANGE), cc.galleryChangeCollector, cc.mainSwatchCollector),
        v.galleryChangeValidator
    );
    // IMAGE end

    // SHOP THE COLLECTION
    window.analytics.click(
        $('.js-stc .swatch').not('.truncated'),
        c.merge(c.main(a.STC_SWATCH_CLICK), cc.stcSwatchCollector)
    );
    window.analytics.on(
        $('.js-stc .js-stc-select-size'),
        'shown.bs.select',
        c.merge(c.main(a.STC_SIZE_DROPDOWN), cc.stcContextCollector, cc.staticExpandCollector)
    );
    window.analytics.on(
        $('.js-stc .js-stc-select-size'),
        'dropdownsChangeHandled',
        c.merge(c.main(a.STC_SIZE_DROPDOWN_CHANGE), cc.stcContextCollector, cc.stcSizeCollector)
    );
    window.analytics.on(
        $('.js-stc .js-stc-select-size'),
        'hidden.bs.select',
        c.merge(c.main(a.STC_SIZE_DROPDOWN), cc.stcContextCollector, cc.staticCollapseCollector)
    );
    window.analytics.on(
        $('.js-stc .js-stc-select-qty'),
        'shown.bs.select',
        c.merge(c.main(a.STC_QTY_DROPDOWN), cc.stcContextCollector, cc.staticExpandCollector)
    );
    window.analytics.on(
        $('.js-stc .js-stc-select-qty'),
        'dropdownsChangeHandled',
        c.merge(c.main(a.STC_QTY_DROPDOWN_CHANGE), cc.stcContextCollector, cc.stcQtyCollector)
    );
    window.analytics.on(
        $('.js-stc .js-stc-select-qty'),
        'hidden.bs.select',
        c.merge(c.main(a.STC_QTY_DROPDOWN), cc.stcContextCollector, cc.staticCollapseCollector)
    );
    window.analytics.click(
        $('.js-stc .add-to-bag'),
        c.merge(c.main(a.STC_ADD_TO_BAG_CLICK), cc.stcContextCollector, cc.stcSizeCollector, cc.stcQtyCollector)
    );
    // SHOP THE COLLECTION end

    // SHOP THE LOOK
    window.analytics.on(
        $('.js-section-look .carousel-look'),
        'afterChange',
        c.merge(c.main(a.STL_CHANGE), cc.galleryChangeCollector, cc.mainSwatchCollector),
        v.galleryChangeValidator
    );
    window.analytics.click(
        $('.js-section-look .swatch').not('.truncated'),
        c.merge(c.main(a.STL_SWATCH_CLICK), cc.stlSwatchCollector)
    );
    window.analytics.on(
        $('.js-section-look .individual-dropdown'),
        'shown.bs.select',
        c.merge(c.main(a.STL_SIZE_DROPDOWN), cc.stlContextCollector, cc.staticExpandCollector)
    );
    window.analytics.on(
        $('.js-section-look .individual-dropdown'),
        'changed.bs.select',
        c.merge(c.main(a.STL_SIZE_DROPDOWN_CHANGE), cc.stlContextCollector, cc.stlIndividualSizeCollector),
        v.stlAddToBagReadyValidator
    );
    window.analytics.on(
        $('.js-section-look .individual-dropdown'),
        'hidden.bs.select',
        c.merge(c.main(a.STL_SIZE_DROPDOWN), cc.stlContextCollector, cc.staticCollapseCollector)
    );
    window.analytics.on(
        $('.js-section-look .master-dropdown'),
        'shown.bs.select',
        c.merge(c.main(a.STL_SIZE_DROPDOWN), cc.stlContextCollector, cc.staticExpandCollector)
    );
    window.analytics.on(
        $('.js-section-look .master-dropdown'),
        'changed.bs.select',
        c.merge(c.main(a.STL_SIZE_DROPDOWN_CHANGE), cc.stlContextCollector, cc.stlSizeCollector),
        v.stlAddToBagReadyValidator
    );
    window.analytics.on(
        $('.js-section-look .master-dropdown'),
        'hidden.bs.select',
        c.merge(c.main(a.STL_SIZE_DROPDOWN), cc.stlContextCollector, cc.staticCollapseCollector)
    );
    window.analytics.on(
        $('.js-section-look .slave-dropdown'),
        'shown.bs.select',
        c.merge(c.main(a.STL_TYPE_DROPDOWN), cc.stlContextCollector, cc.staticExpandCollector)
    );
    window.analytics.on(
        $('.js-section-look .slave-dropdown'),
        'changed.bs.select',
        c.merge(c.main(a.STL_TYPE_DROPDOWN_CHANGE), cc.stlContextCollector, cc.stlTypeCollector),
        v.stlAddToBagReadyValidator
    );
    window.analytics.on(
        $('.js-section-look .slave-dropdown'),
        'hidden.bs.select',
        c.merge(c.main(a.STL_TYPE_DROPDOWN), cc.stlContextCollector, cc.staticCollapseCollector)
    );
    window.analytics.click(
        $('.js-section-look .add-to-bag'),
        c.merge(
            c.main(a.STL_ADD_TO_BAG_CLICK),
            cc.stlContextCollector,
            cc.stlIndividualSizeCollector,
            cc.stlSizeCollector,
            cc.stlTypeCollector
        )
    );
    // SHOP THE LOOK end

    // RECOMMENDATIONS
    $(window).on('recommendationsUpdateFinished', function (e, data) {
        var recommendationsType = _.get(data, 'type');
        if (recommendationsType === 'manual') {
            var recommendationsManualEventPayload = window.analytics.handle(
                RecommendationHelper.getInstance().getManualRecommendations(),
                c.merge(c.main(a.RECOMMENDATION_MANUAL_INIT), cc.recommendationsManualInitCollector),
                v.nonEmptyRecommendationsValidator
            );
            _.set(
                window,
                'universal_variable.listing.initialManualRecommendations',
                _.get(recommendationsManualEventPayload, 'data.products', [])
            );
        } else if (recommendationsType === 'peerius') {
            var recommendationsPeeriusEventPayload = window.analytics.handle(
                RecommendationHelper.getInstance().getPeeriusRecommendations(),
                c.merge(c.main(a.RECOMMENDATION_INIT), cc.recommendationsPeeriusInitCollector),
                v.nonEmptyRecommendationsValidator
            );
            _.set(
                window,
                'universal_variable.listing.initialRecommendations',
                _.get(recommendationsPeeriusEventPayload, 'data.products', [])
            );
        }

        window.analytics.on(
            $('[data-recommendation]'),
            'afterChange',
            c.merge(c.main(a.RECOMMENDATION_CHANGE), cc.galleryChangeCollector, cc.mainSwatchCollector),
            v.galleryChangeValidator
        );
    });
    // RECOMMENDATIONS end

    // LOADMORE
    window.analytics.click($('.load-more-button'), c.merge(c.main(a.REVIEWS_LOADMORE_CLICK), cc.reviewsPageCollector));
    // LOADMORE end

    // SCROLL TO TOP
    window.analytics.click($('.scroll-top'), c.main(a.SCROLL_TO_TOP_CLICK));
    // SCROLL TO TOP end
}

function addToBagTileTemplate(selectTileDiv, addToCart, actionSource) {
    var button = window.addToBagButton.getButton(selectTileDiv);
    var states = window.addToBagButton.stateClasses;

    var failedToAdd = function () {
        button.setState(states.error);
    };

    button.setState(states.adding);

    actionSource = actionSource || 'DetailsPage';

    $.post(ACC.config.baseUrl + 'cart/add', {
        productCodePost: addToCart.sku,
        productQuantity: addToCart.qty,
        CSRFToken: ACC.config.CSRFToken,
        source: actionSource,
        responsive: true
    })
        .success(function (data) {
            if (data.status === 'SUCCESS') {
                button.setState(states.added);

                fireAddToBagResponse(data);
            } else {
                failedToAdd();
            }
        })
        .fail(failedToAdd)
        .always(function (data) {
            if (data !== null && Util.isNotEmpty(data.cartPopupHtml)) {
                // TODO: decommissioning_header remove old overlay
                window.addToBagSnackbar.show(data.cartPopupHtml);
                imageService.initPresets('.add-to-bag-snackbar');
                showAddToBagCartPopup(data);
            }
        });
}

function initAddToBagTileTemplate() {
    $('.js-add-tile-to-bag-button').on('click', function () {
        var selectTileDiv = $(this).closest('div.js-stc-product');
        var collectionGroup = AddToBagTileGroupCollectionHelper.getInstance().getCollectionGroup(selectTileDiv);
        var addToCart = collectionGroup.addToCart;

        addToBagTileTemplate(selectTileDiv, addToCart, 'ShopTheCollection');
    });
}

function initAddToBagTileFragranceTemplate() {
    $('.js-add-tile-to-bag-button').on('click', function () {
        var selectTileDiv = $(this).closest('div.js-stc-product');
        var baseProductData = AddToBagTileGroupCollectionFragranceHelper.getInstance().getBaseProductData(selectTileDiv);
        var addToCart = baseProductData.addToCart;
        addToBagTileTemplate(selectTileDiv, addToCart, 'ShopTheCollection');
    });
}

function initGeneralAddToBag() {
    if (isTileGroupCollectionTemplate() || isTileGroupCollectionGroupTemplateCollectionLevel()) {
        initAddToBagTileTemplate();
    } else if (isTileGroupCollectionFragranceTemplate()) {
        initAddToBagTileFragranceTemplate();
    } else {
        initAddToBagCollectionOrBaseProduct();
    }
}

function initializeAddToBagQuickView() {
    var activeSwatch = $('#product-details .product-swatches li.swatch.active').data('swatch-colour');

    if (_.isEmpty(activeSwatch)) {
        activeSwatch = window.addToBagCommons.getDefaultColour();
    }
    if (_.isEmpty(activeSwatch)) {
        activeSwatch = 'no-colour';
    }

    getAddToBagInstance().initDropdowns(activeSwatch);

    initGeneralAddToBag();
}

// Dynamically changing height based on template type and adjusting visual UI (square vs portait)
function setHeightThumbnails() {
    if (window.matchMedia('(min-width: 992px)').matches) {
        var slideButtonHeight = 42;
        var heightMainImage = $('.slick-slide.slick-current').outerHeight() - slideButtonHeight;
        var thumbnailTrackDesktop = $('#product-thumbnails > .js-carousel-thumbs  > .slick-list');
        var thumbnailSlide = $('#product-thumbnails > .js-carousel-thumbs  > .slick-list > .slick-track > .slick-slide');

        thumbnailTrackDesktop.css({
            'min-height': heightMainImage + 'px',
            'max-height': heightMainImage + 'px'
        });

        if (heightMainImage < 650) {
            thumbnailSlide.addClass('pdp-square__thumbnail');
        }
    }
}

$('#product-thumbnails').on('breakpoint', function (event, slick, direction) {
    setHeightThumbnails();
    setPinchZoomerWrapperHeight();
});

function initializePdp() {
    syncService = new SyncService();
    presetService = new PdpPresetService();
    imageService = new ImageService(presetService, syncService, ACC.config.pdp.preset);

    function recalculateStlSwatchHeight(element) {
        var height;
        if (element) {
            var $heightTarget = $(element)
                .parents('.product-swatches')
                .first();

            height = $heightTarget.css('min-height', '').height();
        } else {
            var heights = [];
            $('#shopTheLook')
                .find('.product-swatches')
                .css('min-height', '')
                .each(function (index, el) {
                    heights.push($(el).height());
                });
            height = heights.sort(function (a, b) {
                return b - a;
            })[0];
        }

        if (height) {
            $('#shopTheLook')
                .find('.product-swatches')
                .css('min-height', height + 'px');
        }
    }

    $(document).ready(function () {
        $('.js-SwatchContainer-toggle').click(function () {
            if ($(this).parents('.js-stc-product').length) {
                var $collectionGroup = $(this).parents('[data-collectiongroupcode-info]');
                var groupCode = $collectionGroup.attr('data-collectiongroupcode-info');
                var $sameCollectionGroups = $('[data-collectiongroupcode-info="' + groupCode + '"]');
                $sameCollectionGroups.find('.SwatchContainer').toggleClass('isExpanded');
            } else {
                $(this)
                    .parent()
                    .toggleClass('isExpanded');
            }
            recalculateStlSwatchHeight(this);
        });

        if (window.analytics) {
            initPdpAnalytics();
        }
        initPdpStatus();
        toggleReadMoreButton();
        $('.load-more-button').click(loadMoreReviews);
        $('.swatch[data-shopthelook-swatch-colourcode]').click(onClickShopTheLookSwatch);
        $('.swatch[data-shopthecollection-swatch-colourcode]').click(onClickShopTheCollectionSwatch);

        $('#swatch-btn-showmore').click(
            {
                showMore: true
            },
            toggleShowMoreSwatches
        );
        $('#swatch-btn-showless').click(
            {
                showMore: false
            },
            toggleShowMoreSwatches
        );

        initGeneralAddToBag();

        // Should be place before imageService.initPresets function
        if (ACC.peeriusPdpOldWidgetEnabled === 'true') {
            initManualRecommendations();
        }

        initImageCarousels();
        initZoom();
        initShopTheLookCarousels();
        initShopTheLookSelectpickers();
        mobilePinchToZoom(true);


        // init thumbs for all elements
        var $imageCarousels = $('.carousel-thumbs, .carousel-product-images');
        var $firstImagesInProductImageCarousel = $(".js-carousel-product-images .img-main[index='0']");
        var $productSwatches = $('#product-details .product-swatches, #product-details');

        var $shopTheLook = $('.carousel-look');
        var $shopTheLookImages = $shopTheLook.find('.product-image');
        var $shopTheLookSwatches = $shopTheLook.find('.product-swatches');

        var $shopTheCollection = $('.js-stc');
        var $shopTheCollectionImages = $shopTheCollection.find('.product-image');
        var $shopTheCollectionSwatches = $shopTheCollection.find('.product-swatches');

        var $recommendations = $('#section-viewed .carousel');
        var $largeModals = $('.modal-img-large');


        imageService.initPresets($productSwatches);
        imageService.initPresets($shopTheLookSwatches);
        imageService.initPresets($shopTheCollectionSwatches);

        imageService.initPresets(
            $imageCarousels.find('.preset').not($firstImagesInProductImageCarousel),
            null,
            false,
            'thumb'
        );
        imageService.initPresets($firstImagesInProductImageCarousel);
        imageService.initPresets($shopTheLookImages, null, false);
        imageService.initPresets($shopTheCollectionImages, null, false);

        imageService.initPresets($recommendations, null, false, 'thumb');
        imageService.initPresets($largeModals, null, false, 'thumb');

        imageService.initPresets(
            null,
            function () {
                imageService.initLazy();
                imageService.forceLazy('.js-section-look:visible .js-stl-swatchinfo-wrap:visible .js-product-image img');
                imageService.triggerLazy();
            },
            true
        );

        toggleMobileScroller();
        initShopTheLookAddToBag();
        initOther();
        $('#shopTheLookBt').click(function (e) {
            e.preventDefault();
            var aid = $(this).attr('href');
            $('html,body').animate({ scrollTop: $(aid).offset().top }, 'slow');
        });

        // Init pinterest
        $('.js-share-pinterest').click(function () {
            window.PinUtils.pinOne({
                url: window.location.href,
                // First image of active swatch
                media: $('img[preset-type=main]:visible:first').attr('src-np'),
                description: $('title')
                    .text()
                    .trim()
            });
        });
    });

    $(document).ready(function () {
        $('.swatch[data-swatch-colour]').click(function (event) {
            onClickSwatch.call(this, event);
            recalculateStlSwatchHeight();
            window.addToBagCommons.toggleTileGroupCollectionWishlistIcon();
        });
        initZoomManual();
        setHeightThumbnails();

        imageService.initPresets(
            null,
            function () {
                imageService.initLazy();
                imageService.forceLazy('.js-section-look:visible .js-stl-swatchinfo-wrap:visible .js-product-image img');
                imageService.triggerLazy();
            },
            true
        );
        window.addToBagCommons.toggleTileGroupCollectionWishlistIcon();

        // Apply hover state changes to wishlist icon
        var filledIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--filled.svg';
        var emptyIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--empty.svg';

        var isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        if (!isTouchDevice) {
            $('.wishlist-icon').mouseover(function () {
                if ($(this).hasClass('heart-filled')) {
                    $(this).attr('src', emptyIconPath);
                } else {
                    $(this).attr('src', filledIconPath);
                }
            }).mouseout(function () {
                if ($(this).hasClass('heart-filled')) {
                    $(this).attr('src', filledIconPath);
                } else {
                    $(this).attr('src', emptyIconPath);
                }
            });
        }
    });

    $(window).on(
        'orientationchange resize',
        $.debounce(150, function () {
            updateShopTheLookCarousels();

            imageService.initPresets(
                null,
                function () {
                    imageService.initLazy();
                    imageService.forceLazy('.js-section-look:visible .js-stl-swatchinfo-wrap:visible .js-product-image img');
                    imageService.triggerLazy();
                },
                true
            );
            toggleMobileScroller();
        })
    );

    document.addEventListener('DOMContentLoaded', function (event) {
        if (document.querySelector('div[data-elem=pinchzoomer]')) {
            var mainImage = document.querySelector('div[data-elem=pinchzoomer] > .img-main');
            mainImage.addEventListener('load', function (event) {
                setHeightThumbnails();
                setPinchZoomerWrapperHeight();
            });
        }
    });
}

/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals
*/

/*
 globals
 define
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(factory);
    } else {
        // Browser globals
        root.imageLoader = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    var imageCache = new Set();
    var pendingImages = new Map();

    /**
     * Load image in background with caching.
     * @param {string} url src attribute of loading image.
     * @returns {Promise<string>} URL of loaded image.
     */
    function loadInBackground(url) {
        if (imageCache.has(url)) {
            return Promise.resolve(url);
        }

        if (pendingImages.has(url)) {
            return pendingImages.get(url);
        }

        var promise = new Promise(function (resolve, reject) {
            var img = new Image();

            img.src = url;
            img.onload = function () {
                imageCache.add(url);
                pendingImages.delete(url);
                resolve(url);
            };
            img.onerror = function () {
                pendingImages.delete(url);
                reject(url);
            };
        });

        pendingImages.set(url, promise);

        return promise;
    }

    return {
        loadInBackground: loadInBackground
    };
}));

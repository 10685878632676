/* eslint-disable
    no-prototype-builtins,
    no-restricted-syntax,
    block-scoped-var,
    no-redeclare,
    no-param-reassign,
    no-unused-vars
*/

/* globals
    $
    ACC
    addToWishlist
    removeFromWishlist
    isProductExistInWishlist
*/

var AddToBagCollectionHelper = (function () {
    var instance;

    function createInstance() {
        var DataHolder = function () {};

        function toggleProductCodeSection() {
            var addToCartForm = $('#addToCartForm');
            var productCode = addToCartForm.find('#productCodePost').val();

            var productCodeSection = $('.product-code-marker');

            if (productCode && productCode !== '') {
                var baseProductCode = productCode.substring(0, 5);
                productCodeSection.text(baseProductCode);
                productCodeSection.removeClass('hide');
            } else {
                productCodeSection.addClass('hide');
            }
        }

        function toggleFindStockInStoreButton(isAddToBagEnabled) {
            var fsisContainer = $('#addToCartForm').siblings('.find-stock-in-store__container');
            var fsisButton = fsisContainer.find('.find-stock-in-store__cta');
            var primaryClass = 'find-stock-in-store__cta--primary';
            var secondaryClass = 'find-stock-in-store__cta--outline';

            if (!fsisButton) {
                return;
            }

            if (
                (isAddToBagEnabled && fsisButton.hasClass(primaryClass))
                || (!isAddToBagEnabled && fsisButton.hasClass(secondaryClass))
            ) {
                fsisButton.toggleClass(primaryClass);
                fsisButton.toggleClass(secondaryClass);
            }
        }

        function toggleAddToBagButton() {
            var addToCartForm = $('#addToCartForm');
            var isCollectionPurchasable = (addToCartForm.find('#isCollectionPurchasable').val() === 'true');
            var isProductSelected = (addToCartForm.find('#productCodePost').val() !== '');
            var isQtySelected = (addToCartForm.find('#productQuantity').val() !== '');

            var isAddToBagEnabled = isCollectionPurchasable && isProductSelected && isQtySelected;

            var button = window.addToBagButton.getButton('#add-to-bag-button');
            button.toggleDisabled(!isAddToBagEnabled);

            toggleFindStockInStoreButton(isAddToBagEnabled);
        }

        function toggleWishlistIcon() {
            if (window.ACC.config.toggles.wishList.CTA.pdpEnabled) {
                var addToCartForm = $('#addToCartForm');
                var currentProductCode = addToCartForm.find('#productCodePost').val();
                var savedItemsObject = window.universal_variable.saved_items;
                var totalNoOfEntries = savedItemsObject.number_of_entries;
                var entriesArray = savedItemsObject.entries;
                var colourCode = null;
                var collectionGroupCode = null;
                var selectedDiv = $('#product-details');
                var selectedWishlistIcon = selectedDiv.find('.wishlist-icon');

                if (!currentProductCode && window.universal_variable.product) {
                    var selectedSwatchContainer = selectedDiv.find('.SwatchContainer');
                    currentProductCode = window.universal_variable.product.id;
                    colourCode = selectedDiv.data('swatchcode-info');
                    collectionGroupCode = selectedDiv.data('collectiongroupcode-info');
                    colourCode = selectedSwatchContainer.find('li.active').data('swatch-colourcode');
                    collectionGroupCode = window.universal_variable.product.groupcode || null;
                }

                var isProductExistInWishlist = window.addToBagCommons
                    .isProductExistInWishlist(currentProductCode, colourCode, collectionGroupCode);

                var filledIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--filled.svg';
                var emptyIconPath = window.ACC.config.commonResourcePath + '/images/saved-items__heart--empty.svg';

                if (isProductExistInWishlist) {
                    if (selectedWishlistIcon.hasClass('heart-empty')) {
                        selectedWishlistIcon.removeClass('heart-empty').addClass('heart-filled');
                        selectedWishlistIcon.attr('src', filledIconPath);
                    }
                } else if (selectedWishlistIcon.hasClass('heart-filled')) {
                    selectedWishlistIcon.removeClass('heart-filled').addClass('heart-empty');
                    selectedWishlistIcon.attr('src', emptyIconPath);
                }
            }
        }

        function isNotifyMeEnabled() {
            return $('#notify-me--enabled').length;
        }

        function displayNotifyMe() {
            var selectedVariantTarget = $('.selectpicker.form-control:not(.hide)')[0];
            var selectedVariantSizeValue = selectedVariantTarget.value;
            var selectedVariantSizeValueNotifyMe = selectedVariantSizeValue.includes('Notify Me');
            var selectedVariantSizeValueDefault = selectedVariantSizeValue.includes('Select size');
            var selectedVariantSizeOptions = selectedVariantTarget.options;
            var atLeastOneVariantIsSetTonotifyMe = Array.from(selectedVariantSizeOptions).every(function (option, index) {
                if (index === 0) {
                    return true;
                }
                return option.dataset.notifyme === 'true';
            });

            if (selectedVariantSizeValueDefault && atLeastOneVariantIsSetTonotifyMe) {
                return {
                    displayBanner: true,
                    displaySizeMessage: true
                };
            }

            if (selectedVariantSizeValueNotifyMe) {
                return {
                    displayBanner: true,
                    displaySizeMessage: false
                };
            }

            return {
                displayBanner: false,
                displaySizeMessage: false
            };
        }

        function toggleNotifyMeBanner() {
            var notifyMeBanner = $('#notify-me-banner');
            var notifyMe = displayNotifyMe();

            // Remove notify me banner if it exists
            if (notifyMeBanner) {
                notifyMeBanner.remove();
            }

            if (notifyMe.displayBanner) {
                var bannerWrapper = document.createElement('div');
                bannerWrapper.classList.add('col-xs-12');
                bannerWrapper.id = 'notify-me-banner';

                var banner = document.createElement('div');
                banner.classList.add('notify-me__banner');

                var bannerIcon = document.createElement('img');
                bannerIcon.classList.add('notify-me__banner-icon');
                bannerIcon.src = window.ACC.config.commonResourcePath + '/images/information-new.svg';

                var bannerContent = document.createElement('div');
                bannerContent.classList.add('notify-me__banner-content');

                var bannerContentTitle = document.createElement('span');
                bannerContentTitle.classList.add('notify-me__banner-content-title');
                bannerContentTitle.innerText = 'This item is currently out of stock';

                var bannerContentBody = document.createElement('span');
                bannerContentBody.classList.add('notify-me__banner-content-body');
                bannerContentBody.innerText = notifyMe.displaySizeMessage
                    ? "Select your size and enter your email and we'll let you know as soon as it's back."
                    : "Enter your email and we'll let you know as soon as it's back.";

                bannerContent.appendChild(bannerContentTitle);
                bannerContent.appendChild(bannerContentBody);

                banner.appendChild(bannerIcon);
                banner.appendChild(bannerContent);

                bannerWrapper.appendChild(banner);

                $('#product-image').before(bannerWrapper);
            }
        }

        function toggleNotifyMeForm() {
            var addToCartForm = $('#addToCartForm');
            var isNotifyMe = (addToCartForm.find('#isNotifyMe').val() === 'true');

            $('#notify-me').removeClass('prompt--show');
            if (isNotifyMe) {
                $('#notify-me').removeClass('submit--success').addClass('prompt--show');
            }
            toggleNotifyMeBanner();
        }

        function changePrice(option) {
            var priceForVariantSelector = $('div.product-price-for-selected-variant');
            $('div[class^="colour-specific-"]').parent().addClass('hide');

            priceForVariantSelector.children().remove();
            $('#priceForSizeTemplate').tmpl({
                wasPrice: option.waspriceformatted,
                price: option.priceformatted
            }).appendTo(priceForVariantSelector);

            priceForVariantSelector.removeClass('hide');
        }

        function resetPrice(swatchColour) {
            $('div.product-price-for-selected-variant').addClass('hide');
            $('div[class^="colour-specific-"]').parent().addClass('hide');
            $('div[class="colour-specific-' + swatchColour + '"]').parent().removeClass('hide');
        }

        function getQteArray(currentStockLevel) {
            var qtyArray = [];
            for (var i = 1; i <= currentStockLevel; i++) {
                qtyArray.push(i);
            }
            return qtyArray;
        }

        function initQty(option, isOneCollectionGroup, isOneSize, sizeOptions, currentSelection) {
            var selectQtyDiv = $('.select-qty');
            var selectQtySelectpicker = selectQtyDiv.find('.selectpicker');
            var selectQteNoStockDiv = selectQtyDiv.find('.no-stock');

            // clean and hide all possible select-qte
            selectQtySelectpicker.addClass('hide');
            selectQtySelectpicker.prop('disabled', true);
            selectQtySelectpicker.find('[data-value]').remove();
            selectQtySelectpicker.selectpicker('refresh');
            selectQteNoStockDiv.addClass('hide');

            // clean form qty
            var addToCartForm = $('#addToCartForm');
            addToCartForm.find('#productQuantity').val('');

            if (isOneCollectionGroup) {
                if (option === null) {
                    // nothing selected so disable with qty text
                    selectQteNoStockDiv.removeClass('hide');
                } else {
                    var currentStockLevel = option.currentstocklevel;

                    if (option.ispurchasable === true && currentStockLevel > 0) {
                        $('#qtyAddOptionsTemplate').tmpl({
                            qtyArray: getQteArray(currentStockLevel),
                            selectedItemQte: 1
                        }).appendTo(selectQtySelectpicker);

                        selectQtySelectpicker.removeClass('hide').parent().removeClass('hide');
                        selectQtySelectpicker.prop('disabled', false);
                        selectQtySelectpicker.selectpicker('refresh');

                        addToCartForm.find('#productQuantity').val(1);
                    } else {
                        selectQteNoStockDiv.removeClass('hide');
                    }
                }
            } else if (option === null && sizeOptions === null) {
                selectQteNoStockDiv.removeClass('hide');
            } else if (option !== null) {
                var currentStockLevel = option.currentstocklevel;

                if (currentSelection > currentStockLevel) {
                    currentSelection = 1;
                }

                $('#qtyAddOptionsTemplate').tmpl({
                    qtyArray: getQteArray(currentStockLevel),
                    selectedItemQte: currentSelection
                }).appendTo(selectQtySelectpicker);

                selectQtySelectpicker.removeClass('hide').parent().removeClass('hide');
                selectQtySelectpicker.prop('disabled', false);
                selectQtySelectpicker.selectpicker('refresh');

                addToCartForm.find('#productQuantity').val(currentSelection);
            } else if (sizeOptions !== null) {
                $('#qtyAddOptionsTemplate').tmpl({
                    qtyArray: getQteArray(10),
                    selectedItemQte: 1
                }).appendTo(selectQtySelectpicker);

                selectQtySelectpicker.removeClass('hide').parent().removeClass('hide');
                selectQtySelectpicker.prop('disabled', false);
                selectQtySelectpicker.selectpicker('refresh');

                addToCartForm.find('#productQuantity').val(1);
            }
            toggleAddToBagButton();
            toggleWishlistIcon();
            toggleNotifyMeForm();
            toggleProductCodeSection();
        }

        function initCollectionGroups(option, isOneCollectionGroup, isOneSize, sizeOptions) {
            var selectCollectionGroupDiv = $('#select-collection-group');
            var selectCollectionGroupSelectpicker = selectCollectionGroupDiv.find('.selectpicker');
            var selectCollectionGroupNoStock = selectCollectionGroupDiv.find('.no-stock');

            // clean and hide all possible collection groups
            selectCollectionGroupDiv.addClass('hide');
            selectCollectionGroupSelectpicker.find('[data-sku]').remove();
            selectCollectionGroupSelectpicker.addClass('hide');
            selectCollectionGroupSelectpicker.prop('disabled', true);
            selectCollectionGroupSelectpicker.selectpicker('refresh');
            selectCollectionGroupNoStock.addClass('hide');

            if (!isOneCollectionGroup) {
                selectCollectionGroupDiv.removeClass('hide');

                if (sizeOptions !== null) {
                    $('#selectCollectionGroupTemplate').tmpl({
                        sizeOptions: sizeOptions
                    }).appendTo(selectCollectionGroupSelectpicker);
                    selectCollectionGroupSelectpicker.removeClass('hide').parent().removeClass('hide');
                    selectCollectionGroupSelectpicker.prop('disabled', false);
                    selectCollectionGroupSelectpicker.selectpicker('refresh');
                } else {
                    selectCollectionGroupNoStock.removeClass('hide');
                }
            }
        }

        function initSelectSize() {
            var colourName = instance.selectedSwatchColour;
            var storeForColour = instance.store[colourName];
            if (!storeForColour) {
                storeForColour = {};
            }
            var selectSizeDiv = $('.select-size');
            var selectSizeSelectpicker = selectSizeDiv.find('.selectpicker');
            var selectSizeNoStockDiv = selectSizeDiv.find('.no-stock');
            var selectSizeOneSizeDiv = selectSizeDiv.find('#size-single-number');

            // clean and hide all possible select-sizes
            selectSizeSelectpicker.find('[data-value]').remove();
            selectSizeSelectpicker.addClass('hide');
            selectSizeSelectpicker.selectpicker('refresh');
            selectSizeNoStockDiv.addClass('hide');
            selectSizeOneSizeDiv.remove();

            // clean sku in form
            var addToCartForm = $('#addToCartForm');
            addToCartForm.find('#productCodePost').val('');
            addToCartForm.find('#productBaseCodePost').val('');
            addToCartForm.find('#isNotifyMe').val(false);
            addToCartForm.find('#isCollectionPurchasable').val(false);

            var option = null;
            var sizeOptions = null;

            var isOneSize = false;
            if (storeForColour.sizeToOptions) {
                isOneSize = Object.keys(storeForColour.sizeToOptions).length === 1;
            }
            var isOneCollectionGroup = storeForColour.isOneCollectionGroup;

            if (isOneCollectionGroup) {
                var firstCollectionGroup = storeForColour.collectiongroups[Object.keys(storeForColour.collectiongroups)[0]];

                if (storeForColour.isPurchasable) {
                    addToCartForm.find('#isCollectionPurchasable').val(true);

                    if (isOneSize) {
                        option = firstCollectionGroup.options[Object.keys(firstCollectionGroup.options)[0]];

                        $('#selectSize-OneCollectionGroup-OneSize-Template').tmpl({
                            isIos: window.browserUtils.isIosDevice(),
                            collectionGroup: firstCollectionGroup,
                            option: option
                        }).appendTo(selectSizeDiv);

                        addToCartForm.find('#productCodePost').val(option.sku);
                    } else {
                        $('#selectSize-OneCollectionGroup-Template').tmpl({
                            isIos: window.browserUtils.isIosDevice(),
                            collectionGroup: firstCollectionGroup
                        }).appendTo(selectSizeSelectpicker);

                        selectSizeSelectpicker.removeClass('hide').parent().removeClass('hide');
                        selectSizeSelectpicker.selectpicker('refresh');
                    }
                } else if (isOneSize) {
                    option = firstCollectionGroup.options[Object.keys(firstCollectionGroup.options)[0]];

                    $('#selectSize-OneCollectionGroup-OneSize-Template').tmpl({
                        isIos: window.browserUtils.isIosDevice(),
                        collectionGroup: firstCollectionGroup,
                        option: option
                    }).appendTo(selectSizeDiv);

                    $('#isNotifyMe').val(option.notifyme);
                    addToCartForm.find('#productCodePost').val(option.sku);
                } else if (isNotifyMeEnabled) {
                    $('#selectSize-OneCollectionGroup-Template').tmpl({
                        isIos: window.browserUtils.isIosDevice(),
                        collectionGroup: firstCollectionGroup
                    }).appendTo(selectSizeSelectpicker);

                    selectSizeSelectpicker.removeClass('hide').parent().removeClass('hide');
                    selectSizeSelectpicker.selectpicker('refresh');
                } else {
                    selectSizeNoStockDiv.removeClass('hide');
                }
            } else if (storeForColour.isPurchasable) {
                addToCartForm.find('#isCollectionPurchasable').val(true);
                $('#selectSize-Template').tmpl({
                    isIos: window.browserUtils.isIosDevice(),
                    sizeToOptions: storeForColour.sizeToOptions
                }).appendTo(selectSizeSelectpicker);

                selectSizeSelectpicker.removeClass('hide').parent().removeClass('hide');
                selectSizeSelectpicker.selectpicker('refresh');
            } else {
                selectSizeNoStockDiv.removeClass('hide');
            }

            initQty(option, isOneCollectionGroup, isOneSize, sizeOptions, null);
            initCollectionGroups(option, isOneCollectionGroup, isOneSize, sizeOptions);
        }

        function onQtyOptionChange() {
            var selected = $(this).find('option:selected');
            $('#addToCartForm').find('#productQuantity').val(selected.data('value'));
            toggleAddToBagButton();
        }

        function getSelectedSize(size, storeForColour) {
            for (var i = 0; i < Object.values(storeForColour.sizeToOptions).length; i++) {
                var value = Object.values(storeForColour.sizeToOptions)[i];
                if (value.name === String(size)) {
                    return value;
                }
            }
            return null;
        }

        function toggleBackorder(selectedOption) {
            if (!selectedOption) {
                return;
            }

            var isBackOrder = selectedOption.data('isbackorder');
            var nextDeliveryDate = selectedOption.data('nextdeliverydate');

            var backOrder = $('.js-backorder');
            var backOrderCopy = backOrder.data('copy');
            var backOrderCopySpan = $('.js-backorder-copy');

            if (nextDeliveryDate) {
                backOrderCopySpan.html(backOrderCopy.replace('{0}', nextDeliveryDate));
            }

            backOrder.toggleClass('hide', !(isBackOrder && nextDeliveryDate));
        }


        function onCollectionTypeOptionChange() {
            var selectedOption = $(this).find('option:selected');
            var sku = selectedOption.data('sku');
            $('#addToCartForm').find('#productCodePost').val(sku);

            var colourName = instance.selectedSwatchColour;
            var storeForColour = instance.store[colourName];
            var isOneCollectionGroup = storeForColour.isOneCollectionGroup;

            if (!isOneCollectionGroup) {
                if (sku !== null && sku !== '' && sku !== undefined) {
                    var option = storeForColour.skuToOptionMap[sku];
                    var currentSelection = $('.select-qty').find('.selectpicker')
                        .find('option:selected')
                        .data('value');
                    initQty(option, isOneCollectionGroup, false, null, currentSelection);

                    var selectedSize = option.size;
                    var sizeOptions = getSelectedSize(selectedSize, storeForColour);
                    if (sizeOptions && !sizeOptions.isOnePrice) {
                        changePrice(option);
                    }
                } else {
                    resetPrice(colourName);

                    var currentSize = $('.select-size').find('.selectpicker').selectpicker('val') || '';
                    if (currentSize !== '') {
                        var selectedSizeData = getSelectedSize(currentSize, storeForColour);
                        if (selectedSizeData && selectedSizeData.isOnePrice && selectedSizeData.options.length > 0) {
                            changePrice(selectedSizeData.options[0]);
                        }
                    }
                }
            }

            toggleAddToBagButton();
            toggleWishlistIcon();
            toggleNotifyMeForm();
            toggleProductCodeSection();

            toggleBackorder(selectedOption);
        }

        function onSizeOptionChange() {
            var selectObject = $(this);
            var colourName = instance.selectedSwatchColour;
            var storeForColour = instance.store[colourName];

            var addToCartForm = $('#addToCartForm');
            addToCartForm.find('#productCodePost').val('');
            addToCartForm.find('#productBaseCodePost').val('');
            addToCartForm.find('#isNotifyMe').val(false);

            var selectedOption = selectObject.find('option:selected');
            var isOneCollectionGroup = storeForColour.isOneCollectionGroup;

            var option = null;
            var sizeData = null;

            if (isOneCollectionGroup) {
                var sku = selectedOption.data('sku');
                if (sku !== null && sku !== '' && sku !== undefined) {
                    option = storeForColour.skuToOptionMap[sku];
                    addToCartForm.find('#productCodePost').val(sku);
                    addToCartForm.find('#productBaseCodePost').val(option.basesku);

                    var notifyMeEnabled = selectedOption.data('notifyme');
                    $('#addToCartForm').find('#isNotifyMe').val(notifyMeEnabled);

                    var firstCollectionGroup = storeForColour.collectiongroups[Object.keys(storeForColour.collectiongroups)[0]];
                    if (!firstCollectionGroup.isOnePrice) {
                        changePrice(option);
                    }
                } else {
                    resetPrice(colourName);
                }
            } else {
                resetPrice(colourName);

                var size = selectedOption.data('value');
                sizeData = getSelectedSize(size, storeForColour);
                if (sizeData && sizeData.isOnePrice && sizeData.options.length > 0) {
                    changePrice(sizeData.options[0]);
                }
            }
            initQty(option, isOneCollectionGroup, false, sizeData, null);
            initCollectionGroups(option, isOneCollectionGroup, false, sizeData);

            toggleBackorder(selectedOption);
        }

        DataHolder.prototype.initDropdowns = function (currentSwatch) {
            var me = this;
            me.store = {};
            me.selectedSwatchColour = '';

            if (currentSwatch) {
                me.selectedSwatchColour = currentSwatch.toLowerCase().replace(new RegExp(' ', 'g'), '-');
            }

            // init functions

            function calculateSizesAndIsOneCollectionGroup(storage) {
                for (var colourName in storage) {
                    if (storage.hasOwnProperty(colourName)) {
                        var storeForColour = storage[colourName];

                        if (Object.keys(storeForColour.collectiongroups).length === 1) {
                            storeForColour.isOneCollectionGroup = true;
                        }
                    }
                }
            }

            function calculateIsPurchasableForSizeAndColour(storage) {
                for (var colourName in storage) {
                    if (storage.hasOwnProperty(colourName)) {
                        var storeForColour = storage[colourName];

                        var isColourPurchasable = false;

                        for (var j = 0; j < Object.values(storeForColour.sizeToOptions).length; j++) {
                            var sizeData = Object.values(storeForColour.sizeToOptions)[j];
                            var isSizePurchasable = false;

                            for (var i = 0; i < sizeData.options.length; i++) {
                                var option = sizeData.options[i];
                                if (option.ispurchasable === true) {
                                    isSizePurchasable = true;
                                    isColourPurchasable = true;
                                    break;
                                }
                            }
                            sizeData.isPurchasable = isSizePurchasable;
                            sizeData.sizeStockLevelMessage = isSizePurchasable
                                ? ''
                                : ACC.message.pdp.stock.soldout;
                        }
                        storeForColour.isPurchasable = isColourPurchasable;
                    }
                }
            }


            function calculateIsPurchasableForCollectionGroup(storage) {
                for (var colourName in storage) {
                    if (storage.hasOwnProperty(colourName)) {
                        var storeForColour = storage[colourName];
                        for (var collectionGroupName in storeForColour.collectiongroups) {
                            if (storeForColour.collectiongroups.hasOwnProperty(collectionGroupName)) {
                                var collectionGroup = storeForColour.collectiongroups[collectionGroupName];

                                var isCollectionPurchasable = false;

                                for (var i = 0; i < collectionGroup.options.length; i++) {
                                    var option = collectionGroup.options[i];
                                    if (option.ispurchasable === true) {
                                        isCollectionPurchasable = true;
                                        break;
                                    }
                                }
                                collectionGroup.isPurchasable = isCollectionPurchasable;
                            }
                        }
                    }
                }
            }

            function calculateIsOnePriceForCollectionGroup(storage) {
                for (var colourName in storage) {
                    if (storage.hasOwnProperty(colourName)) {
                        var storeForColour = storage[colourName];

                        for (var collectionGroupName in storeForColour.collectiongroups) {
                            if (storeForColour.collectiongroups.hasOwnProperty(collectionGroupName)) {
                                var collectionGroup = storeForColour.collectiongroups[collectionGroupName];

                                var isOnePrice = true;
                                var price = null;

                                for (var i = 0; i < collectionGroup.options.length; i++) {
                                    var option = collectionGroup.options[i];
                                    if (i === 0) {
                                        price = option.priceformatted;
                                    } else if (price !== option.priceformatted) {
                                        isOnePrice = false;
                                        break;
                                    }
                                }
                                collectionGroup.isOnePrice = isOnePrice;
                            }
                        }
                    }
                }
            }

            function calculateIsOnePriceForSize(storage) {
                for (var colourName in storage) {
                    if (storage.hasOwnProperty(colourName)) {
                        var storeForColour = storage[colourName];
                        for (var j = 0; j < Object.values(storeForColour.sizeToOptions).length; j++) {
                            var sizeData = Object.values(storeForColour.sizeToOptions)[j];
                            var isOnePrice = true;
                            var price = null;

                            for (var i = 0; i < sizeData.options.length; i++) {
                                var option = sizeData.options[i];
                                if (i === 0) {
                                    price = option.priceformatted;
                                } else if (price !== option.priceformatted) {
                                    isOnePrice = false;
                                    break;
                                }
                            }
                            sizeData.isOnePrice = isOnePrice;
                        }
                    }
                }
            }
            var selectSizeOptions = $('.select-size-option');
            selectSizeOptions.each(function (counter) {
                var selectSizeOption = $(this);
                var colourName = selectSizeOption.data('colourname');
                if (colourName !== null && colourName !== '') {
                    if (!me.store.hasOwnProperty(colourName)) {
                        me.store[colourName] = {
                            sizes: [],
                            collectiongroups: {},
                            sizeToOptions: {},
                            isOneCollectionGroup: false,
                            skuToOptionMap: {}
                        };
                    }

                    var storeForColour = me.store[colourName];

                    var size = selectSizeOption.data('size');
                    var sizeToOptionToUpdate = getSelectedSize(size, storeForColour);
                    if (!sizeToOptionToUpdate) {
                        storeForColour.sizeToOptions[counter] = {
                            options: [],
                            isPurchasable: false,
                            isOnePrice: true,
                            name: String(size),
                            sizeStockLevelMessage: ''
                        };
                        sizeToOptionToUpdate = storeForColour.sizeToOptions[counter];
                    }
                    sizeToOptionToUpdate.options.push(selectSizeOption.data());
                    var collectionGroup = selectSizeOption.data('collectiongroup');
                    if (collectionGroup !== null && collectionGroup !== '') {
                        if (!storeForColour.collectiongroups.hasOwnProperty(collectionGroup)) {
                            storeForColour.collectiongroups[collectionGroup] = {
                                options: [],
                                isPurchasable: false,
                                name: String(collectionGroup),
                                isOnePrice: true
                            };
                        }
                        storeForColour.collectiongroups[collectionGroup].options.push(selectSizeOption.data());
                    }

                    var sku = selectSizeOption.data('sku');
                    if (sku !== null && sku !== '') {
                        storeForColour.skuToOptionMap[sku] = selectSizeOption.data();
                    }
                }
            });

            // remove temp data from DOM
            selectSizeOptions.remove();

            calculateSizesAndIsOneCollectionGroup(me.store);
            calculateIsPurchasableForSizeAndColour(me.store);
            calculateIsPurchasableForCollectionGroup(me.store);
            calculateIsOnePriceForCollectionGroup(me.store);
            calculateIsOnePriceForSize(me.store);
            initSelectSize();

            $('.select-size').find('.selectpicker')
                .on('change', window.addToBagCommons.updateDropdownsAndNotify(onSizeOptionChange));
            $('.select-qty').find('.selectpicker')
                .on('change', window.addToBagCommons.updateDropdownsAndNotify(onQtyOptionChange));
            $('#select-collection-group').find('.selectpicker')
                .on('change', window.addToBagCommons.updateDropdownsAndNotify(onCollectionTypeOptionChange));
        };

        DataHolder.prototype.showSelectSizeAndQuantityBySwatchColour = function (swatchColour) {
            var me = this;
            me.selectedSwatchColour = swatchColour;
            initSelectSize();
        };

        // wishlist icon changes
        $('.wishlist-icon').click(function (e) {
            e.preventDefault();
            var selectedObject = $(this);
            var selectedSliderDiv = selectedObject.closest('div.js-stl-product');
            var productCode = $('#addToCartForm').find('#productCodePost').val();
            var selectedWishlistIcon = $('#addToCartForm').find('.wishlist-icon');
            var colourCode = null;
            var collectionGroupCode = null;
            var selectedDiv = $(this).closest('#product-details');
            var divToSend = selectedDiv; // TODO: this will be changed into ternary when we add quickview changes

            var wishlistContainer = selectedObject.closest('div.wishlist-icon__wrapper');
            wishlistContainer.fadeIn('slow', function () {
                selectedObject.css('display', 'none');
                selectedObject.next().css('display', 'block');
            });

            if (selectedSliderDiv.length) {
                var selectedSliderSwatchContainer = selectedSliderDiv.find('.SwatchContainer');
                var activeSwatch = selectedSliderSwatchContainer.find('li.active');
                var addToBagButton = selectedSliderDiv.find('.add-to-bag');

                if (addToBagButton.hasClass('add-to-bag--add') && !addToBagButton.attr('disabled')) {
                    productCode = addToBagButton.parents('.js-stl-swatchinfo-wrap')
                        .find('.codeholder-dropdown:visible option:selected')
                        .val();
                } else if (selectedSliderSwatchContainer.length) {
                    productCode = activeSwatch.data('shopthelook-swatch-productcode');
                    colourCode = selectedSliderSwatchContainer.length ? activeSwatch.data('shopthelook-swatch-colourcode') : 1;
                    collectionGroupCode = activeSwatch.data('shopthelook-swatch-groupcode');
                } else {
                    selectedSliderSwatchContainer = selectedSliderDiv.find('.product-description');
                    activeSwatch = selectedSliderDiv.find('.product-description > div.js-stl-swatchinfo-wrap');


                    productCode = activeSwatch.data('shopthelook-swatchinfo-productcode');
                    colourCode = activeSwatch.data('shopthelook-swatchinfo-colourcode');
                    collectionGroupCode = activeSwatch.data('shopthelook-swatchinfo-groupcode');
                }
                divToSend = selectedSliderDiv;
            }
            if (!productCode && window.universal_variable.product) {
                productCode = window.universal_variable.product.id;
                colourCode = $('.SwatchContainer').find('li.active').attr('data-swatch-colourcode');
                collectionGroupCode = window.universal_variable.product.groupcode || null;
            }

            var payload = {
                productCode: productCode,
                colourCode: colourCode,
                collectionGroupCode: collectionGroupCode
            };

            if (selectedObject.hasClass('heart-empty')) {
                window.addToBagCommons.checkMaximumWishlistLimitReached(payload, divToSend);
            } else if (selectedObject.hasClass('heart-filled')) {
                var productEntry = window.addToBagCommons.isProductExistInWishlist(productCode, colourCode, collectionGroupCode);
                if (productEntry && productEntry.position) {
                    window.addToBagCommons.removeFromWishlist(productEntry.position, divToSend);
                } else {
                    // TODO: Error handling code will be added here later
                    /* eslint-disable  no-console */
                    console.log('Couldnt perform this operation. Something went wrong');

                    selectedObject.css('display', 'block');
                    selectedObject.next().css('display', 'none');
                }
            }
        });

        return new DataHolder();
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
}());

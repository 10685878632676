/*
 eslint-disable
 no-param-reassign,
 no-restricted-globals
*/

/*
 globals
 define
 Modernizr
*/

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(factory);
    } else {
        // Browser globals
        root.browserUtils = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    function isIosDevice() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    function forceRepaint(element) {
        var tmp = element.style.display;
        element.style.display = 'none';
        element.offsetHeight = 0;
        element.style.display = tmp;
    }

    /**
     * Get third argument for passive event listener in addEventListener.
     * @returns {*}
     */
    function passiveListenerArg() {
        return Modernizr.passiveeventlisteners ? { passive: true } : false;
    }

    return {
        isIosDevice: isIosDevice,
        forceRepaint: forceRepaint,
        passive: passiveListenerArg
    };
}));

/**
 *
 * @param {object} param
 * @param {number} param.fullStars
 * @param {boolean} param.halfStar
 * @param {number} param.emptyStars
 * @return {string[]}
 */
function getStarArray({ fullStars, halfStar, emptyStars }) {
    const data = [];

    for (let i = 0; i < fullStars; i += 1) {
        data.push('fullStar');
    }

    if (halfStar === true) {
        data.push('halfStar');
    }

    for (let i = 0; i < emptyStars; i += 1) {
        data.push('emptyStar');
    }

    return data;
}

/**
 *
 * @param rating
 * @return {{fullStars: number, emptyStars: number, halfStar: boolean}}
 */
function getStarsByRating(rating) {
    const fractPart = rating - Math.floor(rating);
    const intPart = Math.floor(rating);

    const hasHalfStar = fractPart >= 0.25 && fractPart <= 0.75;
    const fullStars = intPart + (fractPart > 0.75 ? 1 : 0);
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return {
        fullStars,
        emptyStars,
        halfStar: hasHalfStar,
    };
}

function getStarImageByType(starType) {
    const path = window.ACC.config.commonResourcePath;
    const star = window.ACC.config.reviews[starType];

    return `<img src="${path}${star.url}" alt="${star.alt}">`;
}

export { getStarArray, getStarsByRating, getStarImageByType };
